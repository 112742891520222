/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useEffect} from 'react'
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { TbTruckDelivery } from "react-icons/tb";
import { NODE_API_URL,BASE_URL } from '../../../../env_config';
import Cookies from "js-cookie";
import {InvoiceExpandDetails} from '../InvoiceExpandDetails'
import { FaFileInvoiceDollar } from 'react-icons/fa';
export const ExpandedDetails = (data) => {
    const [expandData] = React.useState(data.data);
    const [stoneData, setStoneData] = React.useState([]);
    const history = useHistory()
    console.log(data.language,"HEREE")
    useEffect(() => {
        // console.log(data.data,"data.data")
        axios({
            method: "get",
            url: `${NODE_API_URL}/IVP/fetchInvoiceListExpand`,
            headers: {
                "Content-Type": "application/json",
                 "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
            },
            params:{
                "confirm_good_id":data.data.oh_confirm_good_id
            }
        }).then(res => {
            if(res && res.data && res.data.data){
                setStoneData(res.data.data)
            }
        }).
        catch(error => {
          if(error?.response?.data?.message === "UnAuthorized!"){
            var cookies = document.cookie.split(";");
          for (var i = 0; i < cookies.length; i++) {
            if(cookies[i] && !cookies[i].includes("view")){
              var equals = cookies[i].indexOf("=");
              var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
              document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }
          }
          
          window.location.replace(`${BASE_URL}/logout`);
        }
        })
    },[])
    const RouteToDetail = (row) => {
        history.push({
            pathname: `/invoice-detail/${row.oh_sale_id}`,
            state:{
                "oh_confirm_good_id":data.data.oh_confirm_good_id
            }
            });
    }
    const TrackOrder = (invoicedata) => {
        if(invoicedata.shippingNew === "UPS"){
            window.open(`https://wwwapps.ups.com/WebTracking?AgreeToTermsAndConditions=yes&loc=en_IN&tracknum=${invoicedata.tracking_no}&Requester=trkinppg/trackdetails`)
        }
        if(invoicedata.shippingNew === "FEDEX"){
            window.open(`https://www.fedex.com/apps/fedextrack/?action=track&tracknumbers=${invoicedata.tracking_no}&locale=en_US&cntry_code=us`)
            
        }
    }
    console.log(stoneData,"stoneDatastoneData")
    return (
        <>
            <div className="invoiceExpand py-5 px-5">
                <div className="row pt-2 ">
                    <div className="col-12 mb-8 d-flex justify-content-between">
                        <div>
                            <p className='font-size-h3 mb-1'> Shipment Shipped Via {(expandData.shippingNew )} {expandData.invoice_country === "IND-EXP"?"(Shipment From India)":"" }</p>
                            {/* <div className="label label-warning label-inline mr-2">{expandData.shipping_status === 1?"Shipped":expandData.shipping_status} </div> */}
                            <div className={`label label-${expandData.shipping_status === 1?"primary":"warning"} label-inline mr-2`}>{expandData.shipping_status === 0?"Pending":expandData.shipping_status === 1?"Shipped":"Return"} </div>

                        </div>
                        <div className='natTab'>
                            <button className="btn btn-hover-light-dark mr-2" onClick={(e) =>  TrackOrder(expandData) } disabled={expandData.shipping_status === "Pending"}><TbTruckDelivery className='font-size-h4 mr-2' />{data.language.INVOICE_TRACK_ORDER} </button>
                            <span onClick={(e) =>  RouteToDetail(expandData) } className="px-8 btn btn-primary" ><FaFileInvoiceDollar className='font-size-h4 mr-2'/>{data.language.INVOICE_TRACK_VIEW_BILL_INVOICE}</span>
                        </div>
                    </div>
                    <div className="col-12">
                        <InvoiceExpandDetails diamonddetail={stoneData} language={data.language} userinfo={data.userinfo}/>
                    </div>
                </div>
            </div>
        </>
    )
}