import React, { useState,useEffect } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  // Typography,
  Grid,
  CircularProgress
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import axios from "axios"
// import DimondType from './Forms/DimondType';
import MarkupCurrency from './Forms/MarkupCurrency';
import ThemeFrom from './Forms/ThemeFrom';
import LoginFrom from './Forms/LoginFrom';
// import ReviewOrder from './ReviewOrder';
import FormSuccess from './FormSuccess/FormSuccess';
import { NODE_API_URL,BASE_URL } from '../../../../env_config';
import Cookies from "js-cookie"
import validationSchema from './FormModel/validationSchema';
import kycFormModel from './FormModel/kycFormModel';
// import {fetchData} from './FormModel/formInitialValues';
import { FaChevronLeft } from 'react-icons/fa';

import useStyles from './styles';

const steps = ['Brand’s Theme and Multilanguage', 'Markup,Price & Currency', 'Website Setting'];
const { formId, formField } = kycFormModel;

function _renderStepContent(step,setFieldValue,prev,setPrev,values,userinfo,language,SetTaxInclusiveValidation) {
  switch (step) {
    case 0:
      return <ThemeFrom formField={formField} values={values} setFieldValue={setFieldValue} language={language}/>;
    case 1:
      return <MarkupCurrency setFieldValue={setFieldValue} formField={formField} values={values} language={language} SetTaxInclusiveValidation={SetTaxInclusiveValidation}/>;
    case 2:
      return <LoginFrom formField={formField} values={values} setFieldValue={setFieldValue} userinfo={userinfo} language={language}/>;
      // return <ReviewOrder />;
    default:
      return <div>Not Found</div>;
  }
}

export default function IFrameSetupFrom(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [taxinclusive,SetTaxInclusiveValidation] = useState(false)
  // console.log(taxinclusive,"taxinclusive")
  const currentValidationSchema = validationSchema(taxinclusive)[activeStep];
  // console.log(currentValidationSchema,"currentValidationSchema")
  const isLastStep = activeStep === steps.length - 1;
  // const [companylogo,setCompanyLogo] = useState()
  const [initialValues,setInitialValues] = useState({})
  useEffect(() => {
    // fetchData().then(values => {
    //   // console.log(values,"fetchData")
    //   setInitialValues(values)
    // })
    setInitialValues({
       
      muti_currency: 'USD',
      multilanguage:'en',
      wl_primarycolor: '#F9A602',
      n_fontcolor: '#101820',
      lab_primary: '#004900',
      lab_fontcolor: '#101820',
      theme: [],
      tax_name: '',
      tax_value: '',
      email: "",
      timeZone:Intl.DateTimeFormat().resolvedOptions().timeZone
    })
  },[])
  // console.log(initialValues,"initialValues")
  function _sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function _submitForm(values, actions) {
    actions.setSubmitting(false);
    let FinalData = {
      data:[]
    }
    if(values.muti_currency){
      FinalData.data.push({api_currency:values.muti_currency})
    }
    if(values.wl_primarycolor){
      FinalData.data.push({ifm_NaturalPrimaryColor:values.wl_primarycolor})
    }
    if(values.n_fontcolor){
      FinalData.data.push({ifm_NaturalFontColor:values.n_fontcolor})
    }
    if(values.lab_primary){
      FinalData.data.push({ifm_LabPrimaryColor:values.lab_primary})
    }
    if(values.lab_fontcolor){
      FinalData.data.push({ifm_LabFontColor:values.lab_fontcolor})
    }
    if(values.tax_name){
      FinalData.data.push({api_taxname:values.tax_name})
    }
    else{
      FinalData.data.push({api_taxname:""})
    }
    if(values.tax_value){
      FinalData.data.push({api_taxvalue:values.tax_value})
    }
    else{
      FinalData.data.push({api_taxvalue:0})
    }
    if(values.theme){
      FinalData.data.push({ifm_theme:values.theme})
    }
    // if(values.timeZone){
    //   FinalData.data.push({time_zone:values.timeZone})
    // }
    if(values.website_url){
      FinalData.data.push({ifm_website_url:values.website_url})
    }
    if(values.cc_email){
      FinalData.data.push({ifm_inquiry_cc_email:values.cc_email})
    }
    if(values.noprice){
      FinalData.data.push({ifm_no_price:values.noprice})
    }
    if(values.multilanguage){
      FinalData.data.push({ifm_multilingual:values.multilanguage})
    }
    axios({
      method: "POST",
      url: `${NODE_API_URL}/IVP/update-iframe`,
      headers: {
        "Content-Type": "application/json",
         "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
      },
      data: FinalData,
    }).then(res => {
        // console.log(res,"RESPONSEEEE")
        actions.setSubmitting(false);
        if(res.data.success){
          window.location.reload(true)
        }
    }).
    catch(error => {
      actions.setSubmitting(false);
      if(error?.response?.data?.message === "UnAuthorized!"){
        var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        if(cookies[i] && !cookies[i].includes("view")){
          var equals = cookies[i].indexOf("=");
          var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
          document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
      }
      
      window.location.replace(`${BASE_URL}/logout`);
    }
    })
  }

  function _handleSubmit(values, actions) {
    if (isLastStep) {
      _submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }
 const [prev,setPrev] = useState(false)
  function _handleBack() {
    setActiveStep(activeStep - 1);
    setPrev(true)
  }

  return (
    <React.Fragment>
      {activeStep === steps.length ? (
          <></>
        ) : (
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        )}
      <React.Fragment>
        {activeStep === steps.length ? (
          <FormSuccess />
        ) : (
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
          >
            {({ isSubmitting,setFieldValue, values }) => (
              <Form id={formId}>
                {_renderStepContent(activeStep,setFieldValue,prev,setPrev,values,props.userinfo,props.language,SetTaxInclusiveValidation)}
                <Grid className="mt-10 sticky-top" container justify={activeStep !== 0 ? "space-between" : "flex-end"} alignItems="center"  style={{bottom:"0px"}}>
                  {activeStep !== 0 && (
                    <Button color="primary" onClick={_handleBack} className={classes.button}>
                      <FaChevronLeft className="mr-1" /> <span className="mt-1">{props.language.PROFILE_PREVIOUS}</span >
                    </Button>
                  )}
                  <div className={classes.wrapper}>
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                    >
                      {isLastStep ? props.language.CCMODE_COMPLETE_SETUP : props.language.PROFILE_NEXT}
                    </Button>
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </Grid>
              </Form>
            )}
          </Formik>
        )}
      </React.Fragment>
    </React.Fragment>
  );
}
