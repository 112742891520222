/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { toAbsoluteUrl } from "../../_helpers";
import SVG from "react-inlinesvg";

export const TransparentPricing = () => {
    return (
        <div className="container tpricing">
            <div className="card">
                <div className="card-body">
                    <div className="row m-0">
                        <div className="col-12 tpricing_heading">
                            Simple, Transparent Pricing
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-5 price_benefits">
                            <div className="price_benefits_title"> BENEFITS </div>
                            <div className="price_benedits_tile px-md-4 mt-4">
                                <SVG className="text-center" alt="consolidated" title="consolidated" src={toAbsoluteUrl("/media/General/ic_consolidated.svg")} />
                                <span>Consolidated Shipments</span>
                                <i
                                    className="fa fa-check-circle"
                                    aria-hidden="true"
                                ></i>
                            </div>
                            <div className="price_benedits_tile px-md-4 mt-4">
                               
                                <SVG className="text-center" alt="qc" title="qc" src={toAbsoluteUrl("/media/General/ic_qc.svg")} />
                                <span>Independent Standard Quality Check</span>
                                <i
                                    className="fa fa-check-circle"
                                    aria-hidden="true"
                                ></i>
                            </div>
                            <div className="price_benedits_tile px-md-4 mt-4">
                                <SVG className="text-center" alt="inventory" title="inventory" src={toAbsoluteUrl("/media/General/ic_inventory.svg")} />
                                <span>
                                    Live Inventory of 550k+ Natural & 150k+ Lab
                                    Grown Diamonds to Choose from
                                </span>
                                <i
                                    className="fa fa-check-circle"
                                    aria-hidden="true"
                                ></i>
                            </div>
                            <div className="price_benedits_tile px-md-4 mt-4">
                                 <SVG className="text-center" alt="help" title="help" src={toAbsoluteUrl("/media/General/ic_help.svg")} />
                                <span>
                                    Dedicated Account Manager In Your Time Zone
                                </span>
                                <i
                                    className="fa fa-check-circle"
                                    aria-hidden="true"
                                ></i>
                            </div>
                        </div>
                        <div className="col-md-7 px-0 px-md-3">
                            <div className="row price_tile">
                                <div className="col-12 price_tile_title">
                                    <span>Diamond Value</span>
                                    <span>Our Charges</span>
                                </div>
                                <div className="col-12 price_tile_card mb-3 mt-2">
                                    <div>
                                        <i
                                            className="fa fa-check-circle"
                                            aria-hidden="true"
                                        ></i>
                                        <span className="tprice_title">
                                            $0 to $1,250
                                        </span>
                                    </div>
                                    <div>
                                        <span>
                                            <span className="tcharges">$25</span>
                                            <span className="charges_sub_title">
                                                /diamond
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-12 price_tile_card mb-3 mt-2">
                                    <div>
                                        <i
                                            className="fa fa-check-circle"
                                            aria-hidden="true"
                                        ></i>
                                        <span className="tprice_title">
                                            $1,250 to $6,250
                                        </span>
                                    </div>
                                    <div>
                                        <span>
                                            <span className="tcharges">2%</span>
                                            <span className="charges_sub_title">
                                                /diamond
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-12 price_tile_card mb-3 mt-2">
                                    <div>
                                        <i
                                            className="fa fa-check-circle"
                                            aria-hidden="true"
                                        ></i>
                                        <span className="tprice_title">
                                            $6,250 to $21,000
                                        </span>
                                    </div>
                                    <div>
                                        <span>
                                            <span className="tcharges">$125</span>
                                            <span className="charges_sub_title">
                                                /diamond
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-12 price_tile_card mt-2">
                                    <div>
                                        <i
                                            className="fa fa-check-circle"
                                            aria-hidden="true"
                                        ></i>
                                        <span className="tprice_title">
                                            $21,000 & above
                                        </span>
                                    </div>
                                    <div>
                                        <span>
                                            <span className="tcharges">0.60%</span>
                                            <span className="charges_sub_title">
                                                /diamond
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row m-0">
                        <div className="col-12 tpricing_con_heading">
                            convenience fee
                        </div>
                        <div className="col-12 tpricing_con_descr">
                            We charge convenience fee per invoice, depending on what service you prefer to use or what location you are located, this helps us to cater customers with different needs. We will introducing more services in future to help you to serve better to your clients
                            <br />
                            Currently as per your location convenience fee is<span className="font-weight-bolder"> BVC Express : &#8377;499 & SEQUEL &#8377;99</span >
                        </div>
                        <div className="col-12 text-muted mt-4 text-center">
                            For more information, contact your sales executive.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
