/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_helpers";
import { ImPrinter } from 'react-icons/im';
import StockAPI from "./StockAPI.json"
import {DiamondDetails} from "./DiamondDetails"

export const PaymentSuccess = (props) => {

    return (
        <>
            <div className="card custom-card">
                <div className="card-body">
                    <div className="row mt-5 justify-content-center mb-10">
                        <div className="col-12 text-center">
                            <SVG  height={50}  width={50}  src={toAbsoluteUrl("/media/General/ic_fest.svg")} />
                            <div className="display-3 mt-4 text-primary">Thank you</div>
                            <p className="text-dark mt-4 font-weight-bold">Your Invoice number is 123456789</p>
                            <p className="text-dark mt-4 font-weight-bold">We've emailed xyz@vru.com you the receipt.  We'll processing your order in 24 hours.</p>
                            <p className="text-muted mt-4 font-weight-bold">Placed on : 16 June 2021  17:53 </p>
                            <a href="#" className="mt-4 font-weight-bold">View Invoice <ImPrinter className="ml-2 font-size-lg"/></a>
                        </div>
                        <div className="col-md-8 border-top pt-5 mt-5">
                            <div className="row mt-5">
                                <div className="col-md-4 border-md-right">
                                    <div className="font-size-h3 mb-4 font-weight-bold">Delivery Information</div>
                                    <div>
                                        Christine V Allen<br />
                                        115  Oak Lane, Moberly,<br />
                                        MO, Missouri, USA-65270<br />
                                        E: priyanaktodariya@gmail.com<br />
                                        Tel: M: 8866894500
                                    </div>
                                </div> 
                                <div className="col-md-4 border-md-right">
                                    <div className="font-size-h3 mb-4 font-weight-bold">Billing Information</div>
                                    <div>
                                        Christine V Allen<br />
                                        115  Oak Lane, Moberly,<br />
                                        MO, Missouri, USA-65270<br />
                                        E: priyanaktodariya@gmail.com<br />
                                        Tel: M: 8866894500
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="font-size-h3 mb-4 font-weight-bold">Shipping Method</div>
                                    <p className="mb-2 font-weight-bold">FEDEX</p>
                                    <p>( Delivery will be in 1-2 days, If delay well notify you )</p>
                                </div> 
                            </div>
                        </div>
                        <div className="col-md-8 mt-5 mt-md-10 border-bottom pb-5 mt-5">
                            <div className="font-weight-bold mb-4 font-size-h3">Order Summery<span className="small ml-2">({StockAPI.length} items)</span></div>
                            <DiamondDetails data={StockAPI} />
                        </div>
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-12 d-flex mt-2 justify-content-between font-weight-bold">
                                    <div> Sub Total: </div>
                                    <div> $306,100.00 </div>
                                </div>
                                <div className="col-12 d-flex mt-2 justify-content-between font-weight-bold">
                                    <div> Shipping charges: </div>
                                    <div> $100.00 </div>
                                </div>
                                <div className="col-12 d-flex mt-2 justify-content-between font-weight-bold">
                                    <div> Discount: </div>
                                    <div> $13.42 </div>
                                </div>
                                <div className="col-12 d-flex mt-2 justify-content-between font-weight-bold border-bottom border-top py-3">
                                    <div className="font-size-lg"> Total: </div>
                                    <div className="font-size-lg"> $111,322.51 </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
