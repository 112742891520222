/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useMemo,useState} from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import { Modal, Button } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import { useHistory } from "react-router-dom";
import { FaUserCircle ,FaCalendarAlt,FaAward} from "react-icons/fa";
import { BsLightningFill } from "react-icons/bs";
import { ImagePopup } from "./ImagePopUp";
import { VideoPopup } from "./VideoPopUp";
import {
    makeStyles,
    Snackbar,
    IconButton,
    SnackbarContent,
} from "@material-ui/core";
import PropTypes from "prop-types";
import clsx from "clsx";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from "@material-ui/icons/Warning";
// import { green } from '@mui/material/colors'
import green from "material-ui/colors/green";
import red from "material-ui/colors/red";
import { BsCircle,BsCheckCircleFill } from 'react-icons/bs';
// import {IUD_SHORTLIST} from "./../../../../env_config"
// import {DETAILPAGE_LOG} from "./../../../../env_config"
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
// import { StoreDiamondDB } from "../../../_helpers/IndexedDB";
// import { GetDiamondDB } from "../../../_helpers/IndexedDB";
import { Checkbox } from "@material-ui/core";
import { SwalWarn } from "../../Popup";

export const WishlistGridDesignNew = (props) => {
    // console.log(props.stone_status,"WishlistGridDesignNew")
    const history = useHistory();

    const userData = useMemo(()=>localStorage.getItem("userData"),[])
    const user = JSON.parse(userData)

    const [semiAprroveModal, setSemiAprroveModal] = React.useState(false);
    
    // -------------------------------------------------

    const variantIcon = {
        success: CheckCircleIcon,
        warning: WarningIcon,
        error: ErrorIcon,
        info: InfoIcon,
    };

    const useStyles = makeStyles((theme) => ({
        close: {
            padding: theme.spacing(0.5),
        },
        success: {
            backgroundColor: green[600],
        },
        error: {
            backgroundColor: red[600],
        },
    }));

    const [successOpen, setSuccessOpen] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    function MySnackbarContentWrapper(props) {
        const classes = useStyles();
        const { className, message, onClose, variant, ...other } = props;
        const Icon = variantIcon[variant];

        return (
            <SnackbarContent
                className={clsx(classes[variant], className)}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <Icon
                            className={clsx(classes.icon, classes.iconVariant)}
                        />
                        {message}
                    </span>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={onClose}
                    >
                        <CloseIcon className={classes.icon} />
                    </IconButton>,
                ]}
                {...other}
            />
        );
    }

    MySnackbarContentWrapper.propTypes = {
        className: PropTypes.string,
        message: PropTypes.node,
        onClose: PropTypes.func,
        variant: PropTypes.oneOf(["success", "warning", "error", "info"])
            .isRequired,
    };
    // -------------------------------------------------
    const [swalWarnOpen,setSwalWarnOpen] = useState(false)
    const [alertMessage,setAlertMessage] = useState()

    const DetailSemiAprrove = () => {
        setSwalWarnOpen(true)
        setAlertMessage("Your account is under Lite Mode, to get further access please contact your sales representative. Thank you")
    }

    function handleClose(event, reason) {
        setOpen(false);
        setSuccessOpen(false);
        setSemiAprroveModal(false)
    }
   
    function handelDetails(row){
        history.push({
            pathname: `/detail/${row.diamond_type}-${row.id}`
            });
    }
   
   
    if(user === undefined ){
        return
    }

    function numberWithCommas(x) {
        return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    function GetRatio(row) {
        let $ratioval
        if (row.C_Shape !== 'ROUND') {
            $ratioval = (row.C_Length / row.C_Width).toFixed(2);
        } else {
            $ratioval = '-';
        }
        // console.log("ratio",$ratioval)
        return $ratioval
    }
    return (
        <>
            <div
                className={`mb-5 px-2 grid_box ${
                    props.diamond_type === "N" ? "natTab" : "labTab"
                }`}
                key={props.id}
                id={props.id}
            >
                <div className="row m-0 card rounded-0 gridData">
                    <div className="col-12 p-0 d-flex align-items-center justify-content-center bg-gray-200 position-relative" style={{height:"220px"}}>
                        <div className="gridCheck">
                            <Checkbox
                                checked={props.isItemSelected}
                                className=""
                                inputProps={{ 'aria-labelledby': props.labelId }}
                                onClick={(event) => props.handleClick(event, props.id)}
                                icon={<BsCircle />} checkedIcon={<BsCheckCircleFill />}
                            />
                        </div>
                        {props.aws_image ? (
                            // <img
                            // className="img-fluid"
                            // alt={props.C_Shape}
                            // src={props.AWS_Image}
                            //     // src={toAbsoluteUrl(
                            //     //     `/media/shape/${props.C_Shape}-no.png`
                            //     // )}
                                
                            // />
                            user.CustomerType ==="7" || user.CustomerType ==="11" ? (
                                <LazyLoadImage
                                    className="img-fluid cursor-pointer"
                                    onClick={() => DetailSemiAprrove()}
                                    src={ props.aws_image}
                                    alt={props.C_Shape}
                                    onError={e => {
                                        e.currentTarget.src = toAbsoluteUrl(
                                            `/media/shape/${props.C_Shape.toLowerCase()}-no.png`
                                            )
                                        }}
                                    effect="blur" 
                                />
                            ): (
                                <LazyLoadImage
                                    className="img-fluid cursor-pointer"
                                    onClick={() => handelDetails(props)}
                                    src={ props.aws_image}
                                    alt={props.C_Shape}
                                    onError={e => {
                                        e.currentTarget.src = toAbsoluteUrl(
                                            `/media/shape/${props.C_Shape.toLowerCase()}-no.png`
                                            )
                                        }}
                                    effect="blur" 
                                />
                            )
                           
                        ) : (
                            user.CustomerType ==="7" || user.CustomerType ==="11" ? (
                                <img
                                    className="img-fluid cursor-pointer"
                                    onClick={() => DetailSemiAprrove()}
                                    alt="shape"
                                    src={toAbsoluteUrl(
                                        `/media/shape/${props.C_Shape.toLowerCase()}-no.png`
                                    )}
                                />
                            ) :(
                                <img
                                    className="img-fluid cursor-pointer"
                                    onClick={() => handelDetails(props)}
                                    alt="shape"
                                    src={toAbsoluteUrl(
                                        `/media/shape/${props.C_Shape.toLowerCase()}-no.png`
                                    )}
                                />
                            )
                            
                        )}
                    </div>
                    <div className="col-12 mt-3 mb-1 d-flex justify-content-between align-items-center">
                       <div>
                            { user.CustomerType ==="7" || user.CustomerType ==="11" ? (
                                <div className="text-primary d-inline-block cursor-pointer font-weight-bold" onClick={() => DetailSemiAprrove()}>{props.diamond_type + "-" + props.id}</div>
                            ):(
                                    <div className="text-primary d-inline-block cursor-pointer font-weight-bold" onClick={() => handelDetails(props)}>{props.diamond_type + "-" + props.id}</div>
                            )}
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip">
                                        {
                                        props.availability === 'Memo' || props.availability === 'Hold'
                                            ? `Business Process`
                                            : props.availability === "Guaranteed"
                                            ? `Guaranteed`
                                            : props.availability === "Not Available"
                                            ? `Not Available`
                                            : props.availability === "Subject to Prior sale"
                                            ? `Subject to Prior sale`
                                            : `Unknown`
                                        }
                                    </Tooltip>
                                }
                            >
                                <img
                                    className="availIco mx-2"
                                    alt={props.availability}
                                    src={toAbsoluteUrl(`/media/svg/${
                                        props.availability === 'Memo' || props.availability === 'Hold'
                                            ? `availability_primary.svg`
                                            : props.availability === "Guaranteed"
                                            ? `availability_success.svg`
                                            : props.availability === "Not Available"
                                            ? `availability_danger.svg`
                                            : props.availability === "Subject to Prior sale"
                                            ? `availability_warning.svg`
                                            : `availability_blank_white.svg`
                                    }`)}
                                />
                                
                            </OverlayTrigger>
                       </div>
                        <div className="font-weight-bold">
                        <a
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-primary mx-2 text-left"
                                    href={
                                        props.Lab === "IGI"
                                            ? `https://www.igi.org/verify-your-report/?r=LG${props.Certi_NO}`
                                            : props.Lab === "GIA"
                                            ? `https://www.gia.edu/report-check?reportno=${props.Certi_NO}`
                                            : props.Lab === "HRD"
                                            ? `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${props.Certi_NO}`
                                            : props.Lab === "GCAL"
                                            ? `https://www.gcalusa.com/certificate-search.html?certificate_id=${props.Certi_NO}`
                                            : props.Certi_link
                                    }
                                >
                                    {props.Certi_NO}
                                </a>
                        </div>
                        <div> 
                            
                            {props.country ? (
                                <OverlayTrigger
                                    placement="top"
                                    className="text-capitalize"
                                    overlay={
                                        <Tooltip id="tooltip">{props.country}</Tooltip>
                                    }
                                >
                                    <img
                                        className="flagIco mr-1"
                                        alt={props.value}
                                        src={toAbsoluteUrl(
                                            `/media/map/${props.country.toLowerCase()}.png`
                                        )}
                                        onError={e => {
                                            e.currentTarget.src =toAbsoluteUrl(
                                                `/media/map/other.png`
                                                )
                                            }}
                                    />
                                </OverlayTrigger>
                            ) : (
                                null
                            )}
                        </div >
                    </div>
                    <hr className="w-100 my-2" />
                    <div className="col-12 d-flex justify-content-start align-items-center">
                    {/* <div className="d-flex justify-content-center align-items-center"> */}
                        {props.aws_image && (
                            <span>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="tooltip">Image</Tooltip>}
                                >
                                    <ImagePopup
                                        Image={props.aws_image}
                                        Shape={props.C_Shape}
                                    />
                                    {/* <a className="text-primary mx-2 text-center" href={props.aws_image}><BsCardImage/></a> */}
                                </OverlayTrigger>
                            </span>
                        )}
                        {props.video && (
                            <span>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="tooltip">Video</Tooltip>}
                                >
                                    <VideoPopup
                                        ProductId={props.id}
                                        WLDiamondType={props.diamond_type}
                                        Certi_No={props.Certi_NO}
                                        Video={props.video}
                                    />
                                    {/* <a className="text-primary mx-2 text-center" href={props.video}><BsFillCameraVideoFill/></a> */}
                                </OverlayTrigger>
                            </span>
                        )}
                        {props.Certi_NO  && (
                            <span>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="tooltip">Certificate</Tooltip>
                                    }
                                >
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        className="text-primary mx-2 text-left"
                                        href={
                                            props.Lab === "IGI"
                                                ? `https://www.igi.org/verify-your-report/?r=LG${props.Certi_NO}`
                                                : props.Lab === "GIA"
                                                ? `https://www.gia.edu/report-check?reportno=${props.Certi_NO}`
                                                : props.Lab === "HRD"
                                                ? `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${props.Certi_NO}`
                                                : props.Lab === "GCAL"
                                                ? `https://www.gcalusa.com/certificate-search.html?certificate_id=${props.Certi_NO}`
                                                : props.Certi_link
                                        }
                                    >
                                        <FaAward />
                                    </a>
                                </OverlayTrigger>
                            </span>
                        )}
                    {/* </div> */}
                    </div>
                    <hr className="w-100 my-2" />
                    <div className="col-12 d-flex px-2 px-2 align-items-center">
                        <div className="text-center">
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip">
                                        {props.C_Shape}
                                    </Tooltip>
                                }
                            >
                                <SVG width="40px" height="25px" src={toAbsoluteUrl(`/media/shape/svg_shape/${props.C_Shape.toLowerCase()}.svg`)} />
                            </OverlayTrigger>
                            {/* <span className="text-uppercase small">{props.C_Shape}</span > */}
                        </div>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip">
                                    {props.C_Weight.toFixed(2)} {props.C_Shape}{" "}
                                    {(props.C_Color && props.C_Color.toLowerCase() ==="fancy") ? (<>{props.f_intensity} {props.f_overtone} {props.f_color}</>): props.C_Color}{" "}
                                    {props.C_Clarity} {props.C_Cut}{" "}
                                    {props.C_Polish} {props.C_Symmetry} {props.C_Fluorescence}
                                </Tooltip>
                            }
                        >
                            <span className="text-dark font-weight-bold text-capitalize font-size-h5 flex-wrap d-flex ml-1">
                                <span className="pr-2">
                                    {props.C_Weight.toFixed(2)}
                                </span>
                                <span className="pr-2">{props.C_Shape}</span>
                                { (props.C_Color && props.C_Color.toLowerCase() ==="fancy") ?
                                    (
                                        <>
                                             <span className="pr-2">{props.f_intensity}</span>
                                             <span className="pr-2">{props.f_overtone}</span>
                                             <span className="pr-2">{props.f_color}</span>
                                        </>
                                    ) : (
                                        <>
                                             <span className="pr-2">{props.C_Color}</span>
                                        </>
                                    )
                                }
                                <span className="pr-2">{props.C_Clarity}</span>
                                <span className="pr-2">{props.C_Cut}</span>
                                <span className="pr-2">{props.C_Polish}</span>
                                <span className="pr-2">{props.C_Symmetry}</span>
                                <span className="pr-2">{props.C_Fluorescence}</span>
                            </span>
                        </OverlayTrigger>
                    </div>
                    <hr className="w-100 my-2" />
                    <div className="col-12 d-flex justify-content-between ">
                        {
                            props.symbol !=="$" ? <>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="tooltip">
                                            ${numberWithCommas(props.OurRate)}
                                        </Tooltip>
                                    }
                                >
                                    <span className="text-muted font-size-xl font-weight-bold">{props.symbol}/CT: <span className="text-dark font-size-xl">{props.symbol}{numberWithCommas(props.OurRate * props.conversionrate)}</span ></span>
                                </OverlayTrigger>
                            </> :
                            <span className="text-muted font-size-xl font-weight-bold">{props.symbol}/CT: <span className="text-dark font-size-xl">{props.symbol}{numberWithCommas(props.OurRate * props.conversionrate)}</span ></span>
                        }
                        {
                            props.symbol !=="$" ? <>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="tooltip">
                                            ${numberWithCommas(props.OurPrice)}
                                        </Tooltip>
                                    }
                                >
                                    <span className="text-muted font-size-xl font-weight-bold">{props.language.SEARCHGRID_TOTAL}: <span className="text-primary font-size-xl">{props.symbol}{numberWithCommas(props.OurPrice* props.conversionrate)}</span ></span>
                                </OverlayTrigger>
                            </> :
                            <span className="text-muted font-size-xl font-weight-bold">{props.language.SEARCHGRID_TOTAL}: <span className="text-primary font-size-xl">{props.symbol}{numberWithCommas(props.OurPrice* props.conversionrate)}</span ></span>
                        } 
                    </div>
                    {props.stone_status === 1? <div className="label label-outline-success label-pill label-inline bg-success-o-30 px-5">Active</div>:<div className="label label-outline-danger label-pill label-inline bg-danger-o-30 px-5">InActive</div>}
                    <hr className="w-100 my-2" />
                    <div className="col-12 d-flex justify-content-between px-2 mtdr">
                       <span className="text-muted font-size-sm font-weight-bold">M: <span className="text-dark font-size-sm">{props.C_Length}*{props.C_Width}*{props.C_Depth}</span ></span>
                       <span className="text-muted font-size-sm font-weight-bold">T: <span className="text-dark font-size-sm">{props.C_TableP.toFixed(2)}%</span ></span>
                       <span className="text-muted font-size-sm font-weight-bold">D: <span className="text-dark font-size-sm">{props.C_DefthP.toFixed(2)}%</span ></span>
                       {/* <span className="text-muted font-size-sm font-weight-bold">R: <span className="text-dark font-size-sm">{props.Ratio?props.Ratio.toFixed(2):"-"}</span ></span> */}
                       <span className="text-muted font-size-sm font-weight-bold">R: <span className="text-dark font-size-sm">{props.Ratio?props.Ratio.toFixed(2):"-"}</span ></span>
                    </div>
                    <hr className="w-100 my-2" />
                    <div className="col-12 px-2">
                        <span className="d-flex text-muted align-items-center font-size-sm font-weight-bold"> <span className="text-dark-25 mr-2"><FaCalendarAlt /></span> {props.language.SEARCHGRID_SHIPPINGDAYS}: <span className="text-primary font-size-sm ml-1">{<>{props.shipping_days}{" "}{props.language.MUI_DAYS} </>}</span></span>
                        {props.show_supplier === 0 ? (
                            ""
                        ):(
                            <>
                            <OverlayTrigger
                                    placement="top"
                                    className="text-capitalize"
                                    overlay={<Tooltip id="tooltip">{props.C_Name}</Tooltip>}
                                >
                                    <span className="d-flex text-muted align-items-center font-size-sm font-weight-bold mt-2" > <span className="text-dark-25 mr-2"><FaUserCircle /></span> {props.language.MUI_SUPPLIER}: <span className="font-size-sm ml-1 text-dark text-truncate">{props.C_Name}</span></span>
                                </OverlayTrigger></> 
                        )}
                    </div>
                    <hr className="w-100 my-2" />
                    <div className="col-12 text-center mb-2">
                        <button type="button" className="btn btn-primary px-10 text-nowrap" onClick={() => props.BuyDiamond([props.id]) }> <BsLightningFill />{props.language.SEARCHLIST_BUYDIAMONDBTN}</button>
                    </div>
                </div>
            </div>
            <SwalWarn show={swalWarnOpen} message={alertMessage} onClose={() => setSwalWarnOpen(false)} /> 

            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <MySnackbarContentWrapper
                    onClose={handleClose}
                    variant="error"
                    message="Stone already in tracklist"
                />
            </Snackbar>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={successOpen}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <MySnackbarContentWrapper
                    onClose={handleClose}
                    variant="success"
                    message="Stone tracklisted successfully"
                />
            </Snackbar>
        </>
    );
};
