import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { TextField,Grid,FormHelperText } from '@material-ui/core';
import { NODE_API_URL } from "../../../env_config";
import axios from "axios";
export const PasswordUpdatePopUp = (props) => {
    const UpdatePassword = () => {
        const passworMatch =/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/
        if(!passworMatch.test(password)){
            setPasswordError("Password Must Contain Atleast One UpperCase, One LowerCase, One Number and special symbols")
            return
        }
        else{
            setPasswordError("")
        }
        if(password !== confirmpassword){
            setConfirmPasswordError("Password must match")
            return
        }
        else{
            setConfirmPasswordError("")
        }
        const formData = new FormData();        
        if(props.userinfo.id){
          formData.append("CustomerId", props.userinfo.id)
        }
    
        if(props.userinfo.mail){
          formData.append("mail", props.userinfo.mail)
        }
        if(password){
          formData.append("Password", password)
        }
    
        axios({
          method: "POST",
          url: `${NODE_API_URL}/IVP/insertorupdateCCModeSetting`,
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          data: formData,
        }).then(res => {
            // console.log(res,"RESPONSEEEE")
            if(res.data.success){
                props.setPasswordUpdate(false)
            }
        }).catch(err => {
        //   actions.setSubmitting(false);
        })
    }
    const [password,setPassword] = useState("")
    const [confirmpassword,setConfirmPassword] = useState("")
    const [passworderror,setPasswordError] = useState("")
    const [confirmpassworderror,setConfirmPasswordError] = useState("")
    const SetPassword = (password) => {
        const passworMatch =/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/
        setPassword(password)
        if(!passworMatch.test(password)){
            setPasswordError("Password Must Contain Atleast One UpperCase, One LowerCase, One Number and special symbols")
        }
        else{
            setPasswordError("")
        }
    }
    const SetConfirmPassword = (confirmpassword) => {
        setConfirmPassword(confirmpassword)
        if(password !== confirmpassword){
            setConfirmPasswordError("Password must match")
        }
        else{
            setConfirmPasswordError("")
        }
    }
    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.onClose}
                backdrop="static"
                keyboard={false}
                centered
                // size="lg"
            >
                <Modal.Body className="text-center my-6">
                    <h2 className="mb-10 mt-5 text-center">Update CCMode Password</h2> 
                    <div class="mb-5 px-10">
                        <Grid container justify="start" alignItems="center" spacing={3} className="my-10">
                            <Grid item xs={12} md={12}>
                                <TextField
                                    variant="outlined"
                                    type="password"
                                    name={"Password"}
                                    label={"Password"}
                                    style={{ width: "100%" }}
                                    size="normal"
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => SetPassword(e.target.value)}
                                    value={password}
                                    error={passworderror}
                                    helperText={passworderror}
                                />
                            </Grid>
                        </Grid>
                   </div> 
                   <div class="mb-10 px-10">
                        <Grid container justify="start" alignItems="center" spacing={3} className="my-10">
                            <Grid item xs={12} md={12}>
                                <TextField
                                    variant="outlined"
                                    type="password"
                                    name={"ConfirmPassword"}
                                    label={"Confirm Password"}
                                    style={{ width: "100%" }}
                                    size="normal"
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => SetConfirmPassword(e.target.value)}
                                    value={confirmpassword}
                                    error={confirmpassworderror}
                                    helperText={confirmpassworderror}
                                />
                            </Grid>
                        </Grid>
                   </div> 
                    <Button className="btn-primary btn-sm"
                        variant="primary"
                        onClick={() => UpdatePassword()}
                    >
                       Update Password
                    </Button>
                    {/* </div> */}
                </Modal.Body>
            </Modal>
        </div>
    );
};
