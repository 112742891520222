/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useState,useRef} from "react";
import axios from "axios"
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_helpers";
import {FeedTerms} from "../Popup";
import { SwalError } from "../Popup";
import { NODE_API_URL,BASE_URL } from "../../../env_config";
import Cookies from "js-cookie";
import {OverlayTrigger,Tooltip,Overlay} from "react-bootstrap";
import SweetAlert from 'react-bootstrap-sweetalert';

export const FeedRequest = (props) => {
    const [warnMsg,setWarnMsg] = useState('')
    const [feedTermsOpen,setFeedTermsOpen] = useState(false)
    const [selectcheckbox,setSelectCheckbox] = useState(false)
    const [selectcheckboxapi,setSelectCheckboxapi] = useState(false)
    const [showerror,setErrorShow] = useState(false)
    const overlyTarget = useRef(null);
    const overlyTargetApi = useRef(null);
    const [showerrorapi,setErrorShowApi] = useState(false)
    const [loader,setLoader] = useState(false)
    const feedTermsfn = () => {
        setSelectCheckbox(!selectcheckbox)
        setFeedTermsOpen(true)
    }
    const feedTermsfnapi = () => {
        setSelectCheckboxapi(!selectcheckboxapi)
        setFeedTermsOpen(true)
    }
    const RequestForIFRAME = () => {
        if(!selectcheckbox){
            setErrorShow(true)
            setTimeout(() => {
                setErrorShow(false)
                },3000)
        }
        else{
            const finalData = {
                "termsandcondition":1,
                "feed_type":"IFrame Plug-in"
            }
            // console.log(finalData,"finalData")
            setLoader(true)
            axios({
                method: "POST",
                url: `${NODE_API_URL}/IVP/request-for-iframeorapi`,
                headers: {
                    "Content-Type": "application/json",
                     "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
                },
                data: finalData,
            }).then(res => {
                if(res && res.data){
                // console.log(res.data,"RESPONSEDATA")
                if(res.data ===  "Data Updated!"){
                    window.location.reload(true)
                }   
                else{
                    setWarnMsg(res.data)
                }
                setLoader(false)
                }
            }).
        catch(error => {
            setLoader(false)
          if(error?.response?.data?.message === "UnAuthorized!"){
            var cookies = document.cookie.split(";");
          for (var i = 0; i < cookies.length; i++) {
            if(cookies[i] && !cookies[i].includes("view")){
              var equals = cookies[i].indexOf("=");
              var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
              document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }
          }
          
          window.location.replace(`${BASE_URL}/logout`);
        }
        })
        }
    }
    const RequestForAPI = () => {
        if(!selectcheckboxapi){
            setErrorShowApi(true)
            setTimeout(() => {
                setErrorShowApi(false)
                },3000)
        }
        else{
            const finalData = {
                "termsandcondition":1,
                "feed_type":"API"
            }
            // console.log(finalData,"finalData")
            setLoader(true)
            axios({
                method: "POST",
                url: `${NODE_API_URL}/IVP/request-for-iframeorapi`,
                headers: {
                    "Content-Type": "application/json",
                     "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
                },
                data: finalData,
            }).then(res => {
                if(res && res.data){
                    if(res.data ===  "Data Updated!"){
                        window.location.reload(true)
                    }   
                    else{
                        setWarnMsg(res.data)
                    }
                    setLoader(false)
                }
            }).
            catch(error => {
                setLoader(false)
              if(error?.response?.data?.message === "UnAuthorized!"){
                var cookies = document.cookie.split(";");
              for (var i = 0; i < cookies.length; i++) {
                if(cookies[i] && !cookies[i].includes("view")){
                  var equals = cookies[i].indexOf("=");
                  var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                  document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                }
              }
              
              window.location.replace(`${BASE_URL}/logout`);
            }
            })
        }
    }
    return (
        <>
        {warnMsg && (
                <SweetAlert
                    warning
                    title={"Warning!"}
                    onConfirm={()=>setWarnMsg(false)}
                    confirmBtnText="Ok"
                    // cancelBtnText="Cancel"
                    confirmBtnBsStyle="primary"
                    // cancelBtnBsStyle="danger"
                >
                    {warnMsg}
                </SweetAlert>
            )} 
        <Overlay target={overlyTarget.current} show={showerror} placement="top">
                    {props => (
                        <Tooltip id="overlay-example" {...props}>
                            Please check this box if you want to proceed.
                        </Tooltip>
                    )}
                </Overlay>
                <Overlay target={overlyTargetApi.current} show={showerrorapi} placement="top">
                    {props => (
                        <Tooltip id="overlay-example" {...props}>
                            Please check this box if you want to proceed.
                        </Tooltip>
                    )}
                </Overlay>
            {/* <SwalError show={showerror} onClose={() => setErrorShow(false)} onHide={() => setErrorShow(false)} message={"Please Read and Agree our Terms and Condition!"}/> */}
            <div className="row align-content-start position-relative" style={{minHeight: "74vh"}}>
                <div className="col-12">
                   <h3>{props.language.DIAMOND_FEED_CENTER}</h3>
                   <p className="text-muted">{props.language.DIAMOND_FEED_MSG1}</p>
                </div>
                <div className="col-12">
                    <div className="row feed-opt-box" id="title">
                        <div className="col-xl-3 col-lg-5 col-md-6 col-sm-6 py-3">
                            <div className="plugin-api-option-box">
                                <SVG className="dimondAPIFeedIcon" width="40" src={toAbsoluteUrl("/media/General/api.svg")} />
                                <p className="h5 bold mt-3">{props.language.DIAMOND_FEED_API}</p>
                                <p className="small text-muted">{props.language.DIAMOND_FEED_API_MSG}</p>
                                    <div className="row m-0 mb-2" id="enable_api">
                                        <div className="col-12 p-0">
                                            <div className="custom-control custom-radio small" >
                                                <input type="radio" className="custom-control-input acceptterms" ref={overlyTargetApi} name="acceptterms"  onClick={() => feedTermsfnapi()} id="acceptterms" required />
                                                <input type="hidden" name="feed_type" value="API" />
                                                <label className="custom-control-label text-black-50 cursor-pointer" htmlFor="acceptterms" >{props.language.CCOMDE_REQ_PARAGRAPH4}</label>
                                            </div> 
                                            <button type="submit" name="name" id="btnSubmit" className="mt-2 btn btn-primary small px-3 py-2" onClick={RequestForAPI} disabled={loader}>{props.language.DIAMOND_FEED_API_REQUEST}</button>
                                        </div>
                                    </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-5 col-md-6 col-sm-6 py-3">
                            <div className="plugin-api-option-box">
                                <SVG className="dimondAPIFeedIcon" width="40" src={toAbsoluteUrl("/media/General/iframe.svg")} />
                                {/* <img className="api-icon1" width="40" src="<?= base_url() ?>assets/front/images/ccmode_page_icon.svg" /> */}
                                <p className="h5 bold mt-3">{props.language.DIAMOND_FEED_IFRAME}</p>
                                <p className="small text-muted">{props.language.DIAMOND_FEED_IFRAME_MSG}</p>
                                {/* <form id="btnSubmitform" name="api_request" action="<?= base_url('white-label-request'); ?>" method="post" role="form" data-toggle="validator"> */}
                                    <div className="row m-0 mb-2" id="enable_api">
                                        <div className="col-12 p-0">
                                            <div className="custom-control custom-radio small" >
                                                <input type="radio" className="custom-control-input acceptterms" name="acceptterms"  ref={overlyTarget} onClick={() => feedTermsfn()} id="acceptiframeterms" required />
                                                <input type="hidden" name="feed_type" value="API" />
                                                <label className="custom-control-label text-black-50 cursor-pointer" htmlFor="acceptiframeterms" >{props.language.CCOMDE_REQ_PARAGRAPH4}</label>
                                            </div> 
                                            <button type="submit" name="name" id="btnSubmit" className="mt-2 btn btn-primary small px-3 py-2" onClick={RequestForIFRAME} disabled={loader}>{props.language.DIAMOND_FEED_IFRAME_REQUEST}</button>
                                        </div>
                                    </div>
                                {/* </form> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <img src={toAbsoluteUrl("/media/svg/icons/Custom/ccmode_request.svg")} alt="ccmode" className="img-fluid" width="50%" style={{ position: "absolute", bottom: "0", right: "0"}}/> */}
                <div className="api-home-svg"><SVG src={toAbsoluteUrl("/media/General/diamond_feed_request.svg")} /></div>
                <FeedTerms show={feedTermsOpen} onHide={() => setFeedTermsOpen(false)} language={props.language} overlyTarget={overlyTarget} overlyTargetApi={overlyTargetApi} setSelectCheckbox={setSelectCheckbox} setSelectCheckboxapi={setSelectCheckboxapi}/>
            </div>
        </>
    );
};
