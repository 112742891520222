import React, { useState,useEffect } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Grid,
  CircularProgress
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import axios from "axios"
import DimondType from './Forms/DimondType';
import MarkupCurrency from './Forms/MarkupCurrency';
import ThemeFrom from './Forms/ThemeFrom';
import LoginFrom from './Forms/LoginFrom';
// import ReviewOrder from './ReviewOrder';
import FormSuccess from './FormSuccess/FormSuccess';
import { NODE_API_URL,BASE_URL } from '../../../../env_config';
import Cookies from "js-cookie"
import validationSchema from './FormModel/validationSchema';
import kycFormModel from './FormModel/kycFormModel';
import {fetchData} from './FormModel/formInitialValues';
import { FaChevronLeft } from 'react-icons/fa';

import useStyles from './styles';

const steps = ['Brand’s Theme', 'Markup & Currency', 'Login Credentials'];
const { formId, formField } = kycFormModel;

function _renderStepContent(step,setFieldValue,prev,setPrev,values,setCompanyLogo,userinfo,language,SetTaxInclusiveValidation,setBackGroundImage) {
  switch (step) {
    case 0:
      return <ThemeFrom formField={formField} values={values} setFieldValue={setFieldValue} setCompanyLogo={setCompanyLogo} language={language} setBackGroundImage={setBackGroundImage}/>;
    case 1:
      return <MarkupCurrency setFieldValue={setFieldValue} formField={formField} values={values} language={language} SetTaxInclusiveValidation={SetTaxInclusiveValidation}/>;
    case 2:
      return <LoginFrom formField={formField} values={values} setFieldValue={setFieldValue} userinfo={userinfo} language={language}/>;
      // return <ReviewOrder />;
    default:
      return <div>Not Found</div>;
  }
}

export default function CcmodeSetupFrom(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [taxinclusive,SetTaxInclusiveValidation] = useState(false)
  // console.log(taxinclusive,"taxinclusive")
  const currentValidationSchema = validationSchema(taxinclusive)[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const [companylogo,setCompanyLogo] = useState()
  const [backgroundimage,setBackGroundImage] = useState()
  const [initialValues,setInitialValues] = useState({})
  useEffect(() => {
    // fetchData().then(values => {
    //   // console.log(values,"fetchData")
    //   setInitialValues(values)
    // })
    setInitialValues({
       
      muti_currency: 'USD',
      diamondmarkup: '00.00',
      diamondfancymarkup: '00.00',
      lab_diamondmarkup: '00.00',
      lab_diamondfancymarkup: '00.00',
      white_label_logo: '',
      wl_primarycolor: '#3699FF',
      n_fontcolor: '#ffffff',
      lab_primary: '#00c897',
      lab_fontcolor: '#ffffff',
      nav_bar_color: '#ffffff',
      background_color: '#000000',
      password: '',
      theme: [],
      tax_name: '',
      tax_value: '',
      cofirm_password: '',
      email: "",
      timeZone:Intl.DateTimeFormat().resolvedOptions().timeZone,
      displaybuybtn:0,
      background_image:'',
      nav_bar_font: '#000000',
    })
  },[])
  // console.log(initialValues,"initialValues")
  function _sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function _submitForm(values, actions) {
    // console.log(values,"valuesvalues")
    actions.setSubmitting(false);
    const formData = new FormData();
    if(companylogo){
      formData.append("comapny_logo", companylogo[0])
    }
    if(values.white_label_logo){
      formData.append("white_label_logo", values.white_label_logo)
    }
    if(values.muti_currency){
      formData.append("Currency", values.muti_currency)
    }
    if(values.password){
      formData.append("Password", values.password)
    }
    if(props.userinfo.id){
      formData.append("CustomerId", props.userinfo.id)
    }

    if(values.wl_primarycolor){
      formData.append("NaturalPrimaryColor", values.wl_primarycolor)
    }
    if(values.n_fontcolor){
      formData.append("NaturalFontColor", values.n_fontcolor)
    }
    if(values.lab_primary){
      formData.append("LabPrimaryColor", values.lab_primary)
    }
    if(values.lab_fontcolor){
      formData.append("LabFontColor", values.lab_fontcolor)
    }

    if(values.diamondmarkup){
      formData.append("MarkUpNatural", values.diamondmarkup)
    }
    if(values.diamondfancymarkup){
      formData.append("MarkUpNaturalFancy", values.diamondfancymarkup)
    }
    if(values.lab_diamondmarkup){
      formData.append("MarkUpLab", values.lab_diamondmarkup)
    }
    if(values.lab_diamondfancymarkup){
      formData.append("MarkUpLabFancy", values.lab_diamondfancymarkup)
    }
    if(values.tax_name){
      formData.append("TaxName", values.tax_name)
    }
    else{
      formData.append("TaxName", "")
    }
    if(values.tax_value){
      formData.append("TaxValue", values.tax_value)
    }
    else{
      formData.append("TaxValue", 0)
    }
    if(values.theme){
      formData.append("Theme", values.theme)
    }
    if(values.timeZone){
      formData.append("timeZone", values.timeZone)
    }
    if(values.hidePrice >= 0) {
      formData.append("hidePrice", values.hidePrice ? 1 : 0);
    }
    if(values.displaybuybtn >= 0) {
      formData.append("displaybuybtn", values.displaybuybtn ? 1 : 0);
    }
    if (values.hideCertificateNumber >= 0) {
      formData.append("hideCertificateNumber", values.hideCertificateNumber ? 1 : 0);
    }
    if (values.hideEstimatedShippingDays >= 0) {
      formData.append("hideEstimatedShippingDays", values.hideEstimatedShippingDays ? 1 : 0);
    }
    if(values.background_color){
      formData.append("background_color", values.background_color)
    }
    if(values.nav_bar_color){
      formData.append("nav_bar_color", values.nav_bar_color)
    }
    if(backgroundimage){
      formData.append("backgroundimage", backgroundimage[0])
    }
    if(values.background_image){
      formData.append("background_image", values.background_image)
    }
    if(values.nav_bar_font){
      formData.append("nav_bar_font", values.nav_bar_font)
    }
    formData.append("displayName", values.displayName || "");
    axios({
      method: "POST",
      url: `${NODE_API_URL}/IVP/insert-update-ccmode-setting`,
      headers: {
        'Content-Type': 'multipart/form-data',
        "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
      },
      data: formData,
    }).then(res => {
        // console.log(res,"RESPONSEEEE")
        actions.setSubmitting(false);
        if(res.data.success){
          setActiveStep(activeStep + 1);
        }
    }).catch(error => {
      if(error?.response?.data?.message === "UnAuthorized!"){
        var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        if(cookies[i] && !cookies[i].includes("view")){
          var equals = cookies[i].indexOf("=");
          var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
          document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
      }
      
      window.location.replace(`${BASE_URL}/logout`);
    }
    })
  }

  function _handleSubmit(values, actions) {
    if (isLastStep) {
      _submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }
 const [prev,setPrev] = useState(false)
  function _handleBack() {
    setActiveStep(activeStep - 1);
    setPrev(true)
  }

  return (
    <React.Fragment>
      {activeStep === steps.length ? (
          <></>
        ) : (
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        )}
      <React.Fragment>
        {activeStep === steps.length ? (
          <FormSuccess />
        ) : (
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
          >
            {({ isSubmitting,setFieldValue, values }) => (
              <Form id={formId}>
                {_renderStepContent(activeStep,setFieldValue,prev,setPrev,values,setCompanyLogo,props.userinfo,props.language,SetTaxInclusiveValidation,setBackGroundImage)}
                <Grid className="mt-10 sticky-top" container justify={activeStep !== 0 ? "space-between" : "flex-end"} alignItems="center"  style={{bottom:"0px"}}>
                  {activeStep !== 0 && (
                    <Button color="primary" onClick={_handleBack} className={classes.button}>
                      <FaChevronLeft className="mr-1" /> <span className="mt-1">{props.language.PROFILE_PREVIOUS}</span >
                    </Button>
                  )}
                  <div className={classes.wrapper}>
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                    >
                      {isLastStep ? props.language.CCMODE_COMPLETE_SETUP : props.language.PROFILE_NEXT}
                    </Button>
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </Grid>
              </Form>
            )}
          </Formik>
        )}
      </React.Fragment>
    </React.Fragment>
  );
}
