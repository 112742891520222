/* eslint-disable jsx-a11y/anchor-is-valid */
import React ,{useMemo,useState} from "react";
import cs from "../css/widgets.module.css";
import { FaHandHolding } from "react-icons/fa";
import { SwalError,SwalWarn } from "../../Popup";
import axios from "axios"
import { NODE_API_URL } from "../../../../env_config";
export const TotalOrderPlaceTileChurned = (props) => {

  const userData = useMemo(()=>localStorage.getItem("userData"),[])
  const user = JSON.parse(userData)
  const [openerrorpopup,setErrorPopup] = useState(false)
  const [kycmessage,setKycMessage] = useState("")
  const GetKycRejected = () => {
    setErrorPopup(true)
    if(props.userinfo.kyc_status === "KYC_REJ"){
      axios({
        method: "POST",
        url: NODE_API_URL + "/IVP/getKycEditData",
        headers: { "Content-Type": "application/json" },
        data: {
          "user_id":props.userinfo.id
        },
      }).then((res) => {
        if (res && res.data && res.data.success) {
          setKycMessage("Your KYC is Rejected." + " Reason is " + res.data.data.reject_comment_cus)
        }
      });
    }
  }
  return (
    <>
    <SwalWarn show={openerrorpopup} onClose={() => setErrorPopup(false)} message={props.userinfo.kyc_status === "KYC_PEND"?"Your updated KYC is under review, please wait for approval.":props.userinfo.kyc_status === "NO_KYC"?"Your profile is incomplete.":props.userinfo.kyc_status === "KYC_REJ"?kycmessage:""}/>
      <div className={`card card-custom gutter-b ${cs.OrderPlaceTiles} ${cs.dashTileHeight}`}  >
      <a href="/confirmgoods">
            <div className="small-box cart-info-box">
                <div className="inner">
                    <p className="my-2 mt-5 font-size-h6 font-weight-bold">Pending Delivery</p>
                    <p className="display-3 mt-5 font-weight-bold">{props.totalOrderCount ? props.totalOrderCount : "0"}</p>
                </div>
                <div className="icon">
                    <img alt="" src="/media/General/cart_dashboard.svg" />
                </div>
            </div>
          </a>
       
        {/* <a  href="#" >
            <div className="card-body">
              <div className="row align-items-end">
                <div className="col-8">
                  <div className={cs.bgIcon}>
                      <FaHandHolding className={cs.handIcon} />
                  </div>
                <p className="text-light font-weight-bold font-size-lg mt-2 mb-0">{props.language.DASHBOARD_TOTAL_ORDERS}</p>
                </div>
                <div className="col-4 text-right">

                  <div className="text-white font-weight-bold display-3 mt-1"> {props.totalOrderCount ? props.totalOrderCount : "0"} </div>
                </div>
              </div>
            </div>
          </a> */}
      </div>
    </>
  );
}
