import React, {useMemo,useState} from 'react'
import {useTable, useGlobalFilter,useSortBy, usePagination, useRowSelect,useExpanded } from 'react-table';

import StockAPI from "./StockAPI.json"
import { MockDocTableColumn } from './Columns'; 
import GlobleFilter from './GlobleFilter';
import { FaSortDown, FaSortUp, FaAngleLeft, FaAngleRight, FaAngleDoubleLeft, FaAngleDoubleRight, FaArrowAltCircleRight, FaArrowCircleDown } from "react-icons/fa";
import { CheckBox } from './CheckBox';
import { Dropdown, Modal } from 'react-bootstrap';
import { FaEllipsisV } from 'react-icons/fa'
import  ExpandedDetails from "./ExpandedDetails"
export const DemoTable = () => {
    const [CustomizeTable, setCustomizeTable] = useState(false);
    const handleClose = () => setCustomizeTable(false);
    const renderRowSubComponent = React.useCallback(
        () => (
            <>
                <ExpandedDetails />
            </>
        ),[]
      )
    
    const columns = useMemo(()=> MockDocTableColumn,[] )
    const data = useMemo(()=> StockAPI,[])

    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        pageCount,
        visibleColumns, 
        setPageSize,
        setGlobalFilter,
        selectedFlatRows,
        allColumns,
        getToggleHideAllColumnsProps,
        state:{ globalFilter, pageIndex, pageSize, selectedRowIds },
    } = useTable({
        columns,
        data
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
        (hooks) =>{
            hooks.visibleColumns.push((columns)=>{
                return[
                    {
                        id:'selection',
                        Header:({ getToggleAllPageRowsSelectedProps }) =>(
                            <CheckBox title="Select" {...getToggleAllPageRowsSelectedProps()} />
                        ),
                        Cell: ({ row }) => (
                            <div>
                              <CheckBox title="Select" {...row.getToggleRowSelectedProps()} />
                            </div>
                          ),
                    },
                    {
                    Header: () => null,
                    id: 'expander',
                    Cell: ({ row }) => (
                        <span  {...row.getToggleRowExpandedProps()}>
                            {row.isExpanded ? <FaArrowCircleDown className="text-success "  title="Collapse"/> :  <FaArrowAltCircleRight className="text-primary" title="Expand"/>}
                        </span>
                    ),
                    },
                    ...columns,
                ]
            })
        }
    )
    return (
        <>
        <div>
            <div className="card card-custom card-sticky gutter-b">
                <div className="card-header py-3 mb-3">
                    <div className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">{Object.keys(selectedRowIds).length}  Diamond(s) Selected </span>
                        <span className="text-muted font-weight-bold font-size-sm">
                            Total diamonds: 0 diamonds  |  Total Carat :0.00  |  Total $/CT: $0.00  |  Total Payable: $0.00
                        </span>
                    </div>
                    <div className="card-toolbar flex-md-nowrap">
                        <button type="button" className="btn btn-sm btn-light mr-2 text-nowrap">Buy Diamonds</button>
                        <button type="button" className="btn btn-sm btn-light mr-2 text-nowrap">Add to Cart</button>
                        <button type="button" className="btn btn-sm btn-light mr-2 text-nowrap">Hold Diamond</button>
                        <button type="button" className="btn btn-sm btn-light-primary mr-2 text-nowrap">Modified Search</button>
                        <GlobleFilter filter={globalFilter} setFilter={setGlobalFilter} />
                        <div className="search_result_dropdown">
                            <Dropdown >
                                <Dropdown.Toggle size="sm" variant="clear">
                                    <FaEllipsisV />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => setCustomizeTable(true)}>Customize Table</Dropdown.Item>
                                    
                                </Dropdown.Menu>
                            </Dropdown>
                            <Modal
                                size="sm"
                                show={CustomizeTable}
                                onHide={() => setCustomizeTable(false)}
                                aria-labelledby="Customize-table"
                            >
                                <Modal.Header className="px-5 py-3" closeButton>
                                    <Modal.Title id="Customize-table">
                                        Customize Columns
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="p-2" style={{height:"400px", overflow:"auto"}}>
                                    <div  className="p-3" >
                                        <label className="checkbox my-2 border p-2">
                                            <input type='checkbox' {...getToggleHideAllColumnsProps()}  />
                                            <span className="mr-2"></span>
                                            Show All
                                        </label> 
                                        {
                                            allColumns.map(column =>(
                                                <div key={column.id}>
                                                    <label className="checkbox my-2 border p-2">
                                                        <input type='checkbox' {...column.getToggleHiddenProps()}  />
                                                        <span className="mr-2"></span>
                                                        {column.Header}
                                                    </label>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </Modal.Body>
                                <Modal.Footer className="px-5 py-3">
                                    <button type="button" className="btn btn-light mr-2 text-nowrap" onClick={handleClose}>Close</button>
                                    <button type="button" className="btn btn-primary mr-2 text-nowrap" onClick={handleClose}>Save Changes</button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                </div>
                <div className="card-body pt-7 pb-0 mt-n3">
                    <div className="table-responsive w-100">
                        <table className="table table-striped table-hover custom_datatable" {...getTableProps()}>
                            <thead className="custom_datatable_head" >
                                {headerGroups.map(headerGroup =>(
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        { headerGroup.headers.map((column)=>(
                                            <th className="text-center align-middle" {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                {column.render('Header')}
                                                <span className="text-secondary">
                                                    {column.isSorted ? (column.isSortedDesc ? <FaSortDown /> : <FaSortUp />): ''}
                                                </span>
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {page.map((row) => {
                                    prepareRow(row)
                                    return(
                                        <>
                                            <tr {...row.getRowProps()} key={row.id}>
                                                {row.cells.map((cell,i) => {
                                                    return <td  className="text-center align-middle" {...cell.getCellProps({ className: cell.column.className})} key={i}>{cell.render('Cell')}</td>
                                                })}
                                            </tr>
                                            {row.isExpanded ? (
                                                <tr>
                                                    <td className="p-0" colSpan={visibleColumns.length}>
                                                        {renderRowSubComponent({ row })} 
                                                    </td>
                                                </tr>
                                            ) : null}
                                        </>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="card-footer border-0">
                    <div className="d-flex align-items-center justify-content-between">
                        <span className="table_page_length">Page{' '}{pageIndex + 1 } of {pageOptions.length} {' '}</span>
                        <div>
                            <button className="btn btn-sm btn-light mr-2" onClick={()=>gotoPage(0) } disabled={!canPreviousPage}><FaAngleDoubleLeft /></button>
                            <button className="btn btn-sm btn-light mr-2" onClick={() => previousPage()} disabled={!canPreviousPage}><FaAngleLeft /></button>
                            <span className="font-weight-bold text-muted">{'  '}
                                <input
                                    className="form-control d-inline-block form-control-sm"
                                    type="number"
                                    value={pageIndex + 1}
                                    // defaultValue={pageIndex + 1}
                                    onChange={ e => { 
                                        const pageNumber=e.target.value ? Number(e.target.value) - 1 : 0
                                        gotoPage(pageNumber)
                                    }}
                                    style={{maxWidth:'70px'}}
                                />
                                {'  '}of{'  '} {pageOptions.length}{'  '}
                            </span>
                            <button className="btn btn-sm btn-light mr-2" onClick={() => nextPage()} disabled={!canNextPage}><FaAngleRight /></button>
                            <button className="btn btn-sm btn-light" onClick={()=>gotoPage(pageCount - 1)} disabled={!canNextPage}><FaAngleDoubleRight /></button>
                        </div>
                        <span className="d-flex align-items-center">
                        <span className="text-nowrap page_size">Rows Per page:{' '}</span>
                            <select className="form-select ml-2 custom-select py-1" value={pageSize} onChange={e => setPageSize(Number(e.target.value))}>
                                {
                                    [10, 25, 50].map(pageSize => (
                                        <option key={pageSize} value={pageSize}>{pageSize}</option>
                                    ))
                                }
                            </select>
                        </span>
                    </div>
                </div>
            </div>
            <pre>
                <code>
                    {JSON.stringify(
                        {
                            // selectedFlatRows:selectedFlatRows.map((row)=>row.original),
                            selectedRowIds: selectedRowIds,
                            'selectedFlatRows[].original': selectedFlatRows.map( row => row.value ),
                        }, null, 2
                    )}
                </code>
            </pre>
        </div>
        </>
    )
}