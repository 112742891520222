import React,{ useState, useCallback } from 'react'
import "./css/contact.css"
const Maplink = () => {
    const [active, setActive] = useState(1);
  
    const [mapLinkData, setmapLinkData] = useState([
        {
            id: 1,
            location: "Hong kong",
            address: '905 Multifield plaza, \n 3 -7A Prat Ave, Tsim Sha Tsui, Kowloon,HONG KONG',
        },
        {
            id: 2,
            location: "Mumbai",
            address: "CE5040 BHARAT DIAMOND BOURSE,Bandra Kurla Complex, Bandra East,Mumbai, Maharashtra 4000 51, INDIA",
        },
        {
            id: 3,
            location: "Surat",
            address: "B/510 Swastik Universal, Near Valentine Cinema Dumas Road, Piplod, Surat - 395007",
        },
    ]);
  
    const NavLink = ({ id, location, address, onClick, isActive }) => {
      return (
        <div className="col-lg-4 col-md-5 ">
            <div className={isActive ? 'activemaplink maplink' : 'maplink'}  onClick={useCallback(() => onClick(id), [id, onClick])}>
                <h3>{location}</h3>
                <span>Address</span>
                <p>{address}</p>
            </div>
        </div>
      );
    };
  
    const navigate = (id) => {
      setActive(id);
      console.log(id)
    };
  
    return (
      <div className="row">
        {mapLinkData.map((item) => (
            <NavLink key={item.id} {...item} isActive={active === item.id} onClick={navigate} />
        ))}
      </div>
    );
  };
  export default Maplink;
  