import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import SharingComponent from "./SharingComponent";
import ShareSetting from "./ShareSetting";

export const ShareStoneDetails = (props) => {
    const [openShareLink, setOpenShareLink] = useState(false);

    const handleBack = () => {
        setOpenShareLink(false);
    };
      
    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.onClose}
                // backdrop="static"
                // keyboard={false}
                centered
            >
                <Modal.Body >
                    
                    {!openShareLink ?
                        <div className="row">
                            <div className="col-12">
                                <ShareSetting close={props.onClose}/>
                            </div>
                            <div className="col-12 mt-5 text-right">
                                <button className="btn btn-outline-secondary btn-sm px-8" onClick={()=>setOpenShareLink(true)}>Next</button>
                            </div>
                        </div>
                    :
                        <div className="row">
                            <div className="col-12">
                                <SharingComponent onBack={handleBack} close={props.onClose} />
                            </div>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        </div>
    );
};
