/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_helpers";
import { ImPrinter } from 'react-icons/im';


export const PaymentFailed = (props) => {

    return (
        <>
            <div className="card custom-card">
                <div className="card-body" style={{height:'82vh'}}>
                    <div className="row mt-5 justify-content-center mb-10">
                        <div className="col-12 text-center">
                            <SVG  height={50}  width={50}  src={toAbsoluteUrl("/media/General/ic_fest.svg")} />
                            <div className="display-3 mt-4 text-primary">Oh No, Your Payment Failed</div>
                            <p className="text-dark mt-4 font-weight-bold mb-0">We couldn't acquired your Payment . You'll shortly receive email.</p>
                            <p className="text-dark mt-4 font-weight-bold">Transcation no:  12345678911</p>
                            <p className="text-muted mt-4 font-weight-bold">Time : 16 June 2021  17:53 </p>
                        </div>
                        <div className="col-md-8 border-top pt-5 mt-5">
                            <p className="small">* Some issuers put restrictions on payment card usage for electronic or internet purchases. If the payment details you entered are correct, we suggest using the number on the back of your card to contact the issuing bank to learn more about their policies. Please have the exact amount and details of this purchase available when contacting the card issuer.</p>
                        </div>
                        <div className="col-md-8 mt-md-10 mt-5 text-center">
                            <button className="btn btn-primary px-10">Try again</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
