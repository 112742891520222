import React ,{useEffect,useState} from "react"
import axios from "axios"
import { MenuItem, FormControl, Select, makeStyles, InputLabel } from "@material-ui/core"
import { GetCookies,SetCookies,RemoveCookies } from "../../../../_helpers/Cookies"
import { IVPCURRENCY } from "../../../../../env_config"
import { NODE_API_URL } from "../../../../../env_config"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { FaInfoCircle } from "react-icons/fa"

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));
export default function MultiCurrency(props){
    const classes = useStyles();
    const [currValueInr,setCurrValueInr] = React.useState(0);
    const [currValueUsd,setCurrValueUsd] = React.useState(1);
    const [currency, setCurrency] = React.useState('cur_inr');
    const handleChange = (event) => {
      console.log(event.target.value, "event.target.value")
      axios({
        method: "POST",
        url: `${NODE_API_URL}/IVP/updateWebsiteCurrency`,
        headers: {
            "Content-Type": "application/json",
        },
        data: {
          user_id:props.userinfo.id,
          currency:event.target.value
        },
     }).then(response => {
          if(response && response.data){
            setCurrency(event.target.value)
            SetCookies("currency",event.target.value)
            window.location.reload(false);
          }
     })


    }
    useEffect(() => {
            axios({
                method: "POST",
                url: IVPCURRENCY,
                headers: { "Content-Type": "application/json" },
            }).then(response => {
                if(response && response.data && response.data.data){
                    RemoveCookies("conversionrates")
                    console.log("IF CASE")
                   //  console.log(response.data.data,"response.data.data")
                     setCurrValueInr( Math.round(response.data.data[0].cur_inr*100)/100);
                    response.data.data[0].cur_usd = 1
                    SetCookies("conversionrates",JSON.stringify(response.data.data))
                    props.setConversionCookies(response.data.data)
                }
                else{
                  console.log("ELSE CASE")
                  SetCookies("conversionrates",JSON.stringify([{cur_usd:1}]))
                  setCurrency("cur_usd")
                  SetCookies("currency","cur_usd")
                }
            }).catch(error => {
              console.log(error,"Catch CASE")
              SetCookies("conversionrates",JSON.stringify([{cur_usd:1}]))
                  setCurrency("cur_usd")
                  SetCookies("currency","cur_usd")
            })
    },[])
    useEffect(() => {
      setCurrency(props.userinfo.websitecurrency)
    },[props.userinfo])

console.log(currency,"currencycurrency")
 
    return(
          <div className="align-self-center multiCurrencyBox mr-2">
            {/* <div className="d-flex align-items-center position-relative"> */}
              {props.conversioncookies?
                <FormControl className={`${classes.formControl} multi_currency_dropdown m-0`}> 
                  {/* <InputLabel id="demo-simple-select-label">Currency</InputLabel>  */}
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={currency}
                    onChange={handleChange}
                  >
                    {props.conversioncookies?<MenuItem value={"cur_inr"}>INR-{currValueInr}</MenuItem>:""}  
                    <MenuItem value={"cur_usd"}>USD-{currValueUsd}</MenuItem>
                  </Select>
                </FormControl>
              :
                <FormControl className={`${classes.formControl} multi_currency_dropdown m-0`}>
                  {/* <InputLabel id="demo-simple-select-label">Currency</InputLabel> */}
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={"cur_usd"}
                    onChange={handleChange}
                  >
                    <MenuItem value={"cur_usd"}>USD-{currValueUsd}</MenuItem>
                  </Select>
                </FormControl>
              }
              {/* <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip" >Exchange rate shown here are for reference, All purchase and payments will be in US$</Tooltip>} >
                  <span><FaInfoCircle className="text-dark cursor-pointer multiCurrencyIcon text-hover-primary"/></span>
              </OverlayTrigger> */}
            {/* </div> */}
        </div>
    )
}