/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Scrollspy from 'react-scrollspy'
import {BASE_URL} from "../../../env_config"
import { FaDownload } from 'react-icons/fa';
import {Notice, KTCodeExample} from "../controls";
import { toAbsoluteUrl } from "../../_helpers";
// import { BASE_URL } from "../../../env_config";


export const ApiDocumentation = () => {


    return (
        <div className="container-fluid py-8 bg-white">
            <div className="row">
                <div className="col-12 d-md-flex justify-content-between mb-5">
                    <span className="font-size-h4 font-weight-bold">API Documentation</span>
                    <a href={`https://assets.diamondsoncall.com/assets/assets/API_Documentation.pdf`} className="btn btn-secondary"><FaDownload className="mr-2"/>Download Document</a>
                </div>
                <div className="col-12">
                    <div className="row api-doc-section">
                        <div className="col-md-4 col-lg-3 col-xl-2 border-right">
                            <div className="sticky-top" style={{top:'100px'}}>
                                <Scrollspy className="profile-scrollspy" items={ ['Introduction', 'Authentication', 'Diamond-result', 'Diamond-Detail', 'Check-Availability', 'Hold', 'Confirm', 'Errors', 'Test-API'] } currentClassName="is-current">
                                    <li><a href="#Introduction">Introduction</a></li>
                                    <li><a href="#Authentication">Authentication</a></li>
                                    <li><a href="#Diamond-result">Diamond Result</a></li>
                                    <Scrollspy className="profile-scrollspy ml-8" items={ ['Natural-lab-Diamond', 'parameters', 'filter', 'sorting', 'fancy-color'] } >
                                        <li><a href="#Natural-lab-Diamond">Natural and Lab-grown Diamond</a></li>
                                        <li><a href="#parameters">Parameters</a></li>
                                        <li><a href="#filter">Filter</a></li>
                                        <li><a href="#sorting">Sorting</a></li>
                                        <li><a href="#fancy-color">Fancy Color</a></li>
                                    </Scrollspy>
                                    <li><a href="#Diamond-Detail">Diamond Detail</a></li>
                                    <li><a href="#Check-Availability">Check Availability</a></li>
                                    <li><a href="#Hold">Hold</a></li>
                                    <li><a href="#Confirm">Confirm Diamond</a></li>
                                    <li><a href="#Errors">Errors</a></li>
                                    <li><a href="#Test-API">Test API</a></li>
                                </Scrollspy>
                            </div>
                        </div>
                        <div className="col-md-8 col-lg-9 col-xl-10 mt-3">
                            <section id="Introduction">
                                <h3 className="mb-5 border-bottom"><div className="anchorspace"></div>Introduction</h3>
                                <div className="">
                                    <p className="mb-2">The Diamonds On Call API is organized around <strong>REST</strong>. Our API has predictable resource-oriented URLs, accepts&gt;form-encoded request bodies, returns <strong>JSON-encoded</strong> responses, and uses standard HTTP response codes, authentication, and verbs.</p>
                                    <p className="mb-2">You can activate test mode using API Setting, which does not affect your live data or interact with suppliers. The API key you use to <strong>authenticate</strong> the request determines whether the request is an active mode or test mode.</p>
                                    <p className="mb-2"><strong>Base URL: </strong> https://api.diamondsoncall.com/feed</p>
                                    <p className="mb-2">The Diamonds on call API Docs demonstrate using curl to interact with the API over HTTP.</p>
                                </div>
                            </section>
                            <section className='mt-10' id="Authentication">
                                <h3 className="mb-5 border-bottom"><div className="anchorspace"></div>Authentication</h3>
                                <div className="row">
                                    <div className="col-md-6">
                                        <p>The Diamonds On Call API uses API keys to authenticate requests. You can view and manage your API Key using the API setting under Diamond Feed Centre.</p>
                                        <p> Once you Generate the Key, you will need to generate the token using the URL below and key. URL: https://api.diamondsoncall.com/feed/login?apikey=</p>
                                        <p>Token generated using this method will authenticate all the other API.</p>
                                        <p>Your API key and token carry many privileges, so be sure to keep them secure. The secret API key and token should not be shared in publicly accessible areas such as GitHub, client-side code, and so forth. We at Diamonds on Call allow you to keep the API key secure by allowing you to generate new keys by clicking on Generate key under the API setting, also you can generate a new token every time you run the authentication process.</p>
                                        <p> Authentication to the API is performed via <strong>HTTP Basic Auth.</strong> Provide your API key as the basic auth username value. You do not need to provide a password.</p>
                                        <p>All API requests must be made over <strong>HTTPS</strong>. Calls made over plain HTTP will fail. API requests without authentication will also fail.</p>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="sticky-top" style={{top:'100px'}}>
                                            <KTCodeExample jsCode={AuthenticationInput} beforeCodeTitle="INPUT CODE" classname="input-code"></KTCodeExample>
                                            <KTCodeExample jsCode={AuthenticationResponse} beforeCodeTitle="RESPONSE DATA" classname="response-code"></KTCodeExample>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section  className='mt-10' id="Diamond-result">
                                <h3 className="mb-5 border-bottom"><div className="anchorspace"></div>Diamond-result</h3>
                                <div className="row">
                                    <div className="col-md-6">
                                        <section  className='mt-10' id="Natural-lab-Diamond">
                                            <h4 className="mb-5 border-bottom"><div className="anchorspace"></div>Natural & Lab-Grown Diamonds</h4>
                                            <p>The Diamond API is divided into two parts Natural Diamond API and Lab Grown Diamonds API. Both APIs can be fetched through the following URL using the POST Method.</p>
                                            <p><strong>Natural Diamond:</strong> https://api.diamondsoncall.com/feed/naturaldiamond</p>
                                            <p><strong>Lab Grown Diamond:</strong> https://api.diamondsoncall.com/feed/labgrowndiamond</p>
                                            <p>These API will give you results for all diamonds, including all fancy shapes and fancy colors. API works in pagination format, where you will get 100 stones on each page, and a link for the next page will be given in the response. API is designed in such a way to help you sort and filter all the necessary fields to provide you an accurate response.</p>
                                        </section>
                                        <section  className='mt-10' id="parameters">
                                            <h4 className="mb-5 border-bottom"><div className="anchorspace"></div>Parameters</h4>
                                            <p>Following are the parameters for the INPUTS</p>
                                            <code className="api-doc_para">"stock_id"</code>
                                            <p className="m-0">Example Inputs: 1243xx, 3252xx4</p>
                                            <p className="mb-2">You can request multiple stock id in a single run (optional)</p>
                                            <hr className="w-100" />
                                            {/* <code className="api-doc_para">"certificate_no"</code>
                                            <p className="m-0">Example Inputs : 3435623456,2352523454</p>
                                            <p className="mb-2">You can request multiple certificates numbers in single run (optional)</p>
                                            <hr className="w-100" /> */}
                                            <code className="api-doc_para">"Shape"</code>
                                            <p className="mb-2">Accepted Inputs: Round, Radiant, Princess, Pear, Oval, Marquise, Heart, Emerald, Cushion, Asscher, Square Radiant, Trilliant, Cushion modified, Triangle<br /> You can request multiple shapes in a single run (optional)
                                            </p>
                                            <hr className="w-100" />
                                            <code className="api-doc_para">"Color"</code>
                                            <p className="m-0">Accepted Inputs: D, E, F, G, H, I, J, K, L, M, N, OP, QR, ST, UV, WX, YZ</p>
                                            <p className="mb-2">You can request multiple colors in a single run (mandatory)</p>
                                            <hr className="w-100" />
                                            <code className="api-doc_para">"Clarity"</code>
                                            <p className="m-0">Accepted Inputs: FL, IF, VVS1, VVS2, VS1, VS2, SI1, SI2, I1, I2</p>
                                            <p className="mb-2">You can request multiple Clarity in a single run (mandatory)</p>
                                            <hr className="w-100" />
                                            <code className="api-doc_para">"carat"</code>
                                            <p className="m-0">Example Inputs: 1.05-1.05, 2.00-9.99, 0.70-0.75</p>
                                            <p className="mb-2">Carat need to be called in range even you request for specific carat size <br /> You can request multiple carat ranges in a single run (optional)</p>
                                            <hr className="w-100" />
                                            <code className="api-doc_para">"Cut"</code>
                                            <p className="m-0">Accepted Inputs: ID, EX, VG, GD, FR, PR</p>
                                            <p className="mb-2">You can request multiple cut in a single run (optional)</p>
                                            <hr className="w-100" />
                                            <code className="api-doc_para">"Polish"</code>
                                            <p className="m-0">Accepted Inputs: EX, VG, GD, FR, PR</p>
                                            <p className="mb-2">You can request multiple polish in a single run (optional)</p>
                                            <hr className="w-100" />
                                            <code className="api-doc_para">"Symmetry"</code>
                                            <p className="m-0">Accepted Inputs: EX, VG, GD, FR, PR</p>
                                            <p className="mb-2">You can request multiple symmetries in a single run (optional)</p>
                                            <hr className="w-100" />
                                            <code className="api-doc_para">"Fluorescence"</code>
                                            <p className="m-0">Accepted Inputs: NON, STG, FNT, MED, SLT, VSLT, VSTGR</p>
                                            <p className="mb-2">You can request multiple fluorescence in a single run (optional)</p>
                                            <hr className="w-100" />
                                            <code className="api-doc_para">"Lab"</code>
                                            <p className="m-0"> Accepted Inputs: GIA, IGI, HRD, GCAL</p>
                                            <p className="mb-2">You can request multiple labs in a single run (optional)</p>
                                            <hr className="w-100" />
                                            <code className="api-doc_para">"Shade"</code>
                                            <p className="m-0">Accepted Inputs: None, Brown, Green, Grey, Black, Blue, Pink, Light Brown, Light Green, Mix tinge</p>
                                            <p className="mb-2">You can request multiple shade in a single run (optional)</p>
                                            <hr className="w-100" />
                                            <code className="api-doc_para">"Milky"</code>
                                            <p className="m-0">Accepted Inputs: No Milky, Light Milky, Milky</p>
                                            <p className="mb-2">You can request multiple Milky in a single run (optional)</p>
                                            <hr className="w-100" />
                                            <code className="api-doc_para">"eye_clean"</code>
                                            <p className="m-0">Accepted Inputs: Yes, No</p>
                                            <p className="mb-2">No will include results for Not Eye Clean & also for No Data Available<br /> You can request one option in a single run (optional)</p>
                                            <hr class="w-100" />
                                            <code class="api-doc_para">"currency"</code>
                                            <p class="m-0">Accepted Inputs: USD,INR</p>
                                            <p class="mb-2">You can request one option in a single run (optional)<br />If you do not input anything default currency is INR</p>
                                        </section>
                                        <section  className='mt-10' id="filter">
                                            <h4 className="mb-5 border-bottom"><div className="anchorspace"></div>Filter</h4>
                                            <p className="bold m-0"><u> Price Per Carat : Filter</u></p>
                                            <code className="api-doc_para">"Pricepercts_min" "pricepercts_max"</code>
                                            <p className="m-0">Example Input: pricepercts_min=400, pricepercts_max=900</p>
                                            <p className="mb-2">This will give you result for all diamonds between 400$/ct & 900$/ct<br />You can request one option for each in a single run (optional)</p>
                                            <hr className="w-100" />
                                            <p className="bold m-0"><u>Total Price: Filter</u></p>
                                            <code className="api-doc_para">"totalprice_min" "totalprice_max"</code>
                                            <p className="m-0">Example Input: totalprice_min =2000, totalprice_max =3000</p>
                                            <p className="mb-2">This will give you result for all diamonds between 2000$ & 3000$ total price.<br />You can request one option for each in a single run (optional)</p>
                                            <hr className="w-100" />
                                            <p className="bold m-0"><u>Measurements (MM) : Filter</u></p>
                                            <code className="api-doc_para">"length_min" "length_max"</code>
                                            <code className="api-doc_para">"width_min" "width_max"</code>
                                            <code className="api-doc_para">"depth_min" "depth_max"</code>
                                            <p className="m-0">Example Input: length_min =5.5, length_max =5.6</p>
                                            <p className="mb-2">This will give you result for all diamonds with measurement length between 5.5MM & 5.6MM.<br />You can request one option for each in a single run (optional)</p>
                                            <hr className="w-100" />
                                            <p className="bold m-0"><u>Table & Depth Percentage : Filter</u></p>
                                            <code className="api-doc_para">"table_per_from" "table_per_to"</code>
                                            <code className="api-doc_para">"depth_per_from" "depth_per_to"</code>
                                            <p className="m-0">Example Input : table_per_from=56, table_per_to=58</p>
                                            <p className="mb-2">This will give you result for all diamonds with table percentage between 56% & 58%<br />You can request one option for each in single run (optional)</p>
                                            <hr className="w-100" />
                                            {/* <p className="bold m-0"><u>Crown - Pavilion Angle & Height : Filter</u></p>
                                            <code className="api-doc_para">"crown_angle_from" "crown_angle_to"</code><br />
                                            <code className="api-doc_para">"crown_height_from" "crown_height_to"</code><br />
                                            <code className="api-doc_para">"pavilion_angle_from" "pavilion_angle_to"</code><br />
                                            <code className="api-doc_para">"pavilion_height_from" "pavilion_height_from"</code>
                                            <p className="m-0">Example Input : crown_height_from=13.5, crown_height_to=158</p>
                                            <p className="mb-2">This will give you result for all diamonds with Crown height between 13.5% & 15%<br />You can request one option for each in single run (optional)</p>
                                            <hr className="w-100" /> */}
                                            <p className="bold m-0"><u>IMAGE & VIDEO: FILTER</u></p>
                                            <code className="api-doc_para">"image_video"</code>
                                            <code className="api-doc_para">"depth_per_from" "depth_per_to"</code>
                                            <p className="m-0">Example Input : table_per_from=56, table_per_to=58</p>
                                            <p className="mb-2">To filter image and video, use this parameter<br />
                                            only video enter <strong>"1"</strong><br />
                                            only image enter <strong>"2"</strong><br />
                                            both image videos enter <strong>"3"</strong><br />
                                            image or video, whichever available enter <strong>"4"</strong><br />
                                            You can request one option for each in single run (optional)<br />
                                            (if you do not input anything, you will get all results without any filter)</p>
                                        </section>
                                        <section  className='mt-10' id="sorting">
                                            <h4 className="mb-5 border-bottom"><div className="anchorspace"></div>Sorting</h4>
                                            <p>These input parameters help you sort specific parameters like Carat, Color, Clarity, Price Per Carat & Total Price in ascending (asc) or descending (desc) manner.</p>
                                            <code className="api-doc_para">"Sort_field"</code>
                                            <p className="m-0">Accepted Inputs : carat, color, clarity, pricepercts, totalprice</p>
                                            <p className="mb-2">You can request one option in a single run (optional)</p>
                                            <hr className="w-100" />
                                            <code className="api-doc_para">"sort_order"</code>
                                            <p className="m-0">Accepted Inputs : asc, desc</p>
                                            <p className="mb-2">You can request one option in a single run (optional)</p>
                                        </section>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="sticky-top" style={{top:'100px'}}>
                                            <KTCodeExample jsCode={NaturalLabInput} beforeCodeTitle="INPUT CODE" classname="input-code"></KTCodeExample>
                                            <KTCodeExample jsCode={NaturalLabResponse} beforeCodeTitle="RESPONSE DATA" classname="response-code"></KTCodeExample>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <section  className='mt-10' id="fancy-color">
                                            <h4 className="mb-5 border-bottom"><div className="anchorspace"></div>Fancy color</h4>
                                            <p>To request fancy color, please pass the "yes" parameter as below.</p>
                                            <code className="api-doc_para">"fancy_color_diamond"</code> = Yes
                                            <p className="mb-2">once you pass the above parameter, you will be able to pass input for the below parameters</p>
                                            <hr className="w-100" />
                                            <code className="api-doc_para">"fancy_color"</code>
                                            <p className="mb-2">Yellow, Pink, Blue, Red, Green, Purple, Orange, Violet, Gray, Black, Brown, Champagne, Cognac, Chameleon, White</p>
                                            <hr className="w-100" />
                                            <code className="api-doc_para">"fancy_intensity"</code>
                                            <p className="mb-2">Faint, Very Light, Light, Fancy Light, Fancy, Fancy Dark, Fancy Intense, Fancy Vivid, Fancy Deep</p>
                                            <hr className="w-100" />
                                            <code className="api-doc_para">"fancy_overtone"</code>
                                            <p className="mb-0">Yellowish, Pink, Yellow, None, Pinkish, Blue, Blueish, Red, Reddish, Green, Greenish, Purple, Purplish, Orange, Orangy, Violet, Violetish, Gray, Grayish, Black, Brownish, Brown, Champagne, Cognac, Chameleon, White</p>
                                            <p className="mb-2">You can request multiple options in a single run (optional)</p>
                                            <hr className="w-100" />
                                        </section>
                                    </div>
                                    <div className="col-md-6 mt-15">
                                        <div className="sticky-top" style={{top:'100px'}}>
                                            <KTCodeExample jsCode={FancyColorInput} beforeCodeTitle="INPUT CODE" classname="input-code"></KTCodeExample>
                                            <KTCodeExample jsCode={FancyColorResponse} beforeCodeTitle="RESPONSE DATA" classname="response-code"></KTCodeExample>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className='mt-10' id="Diamond-Detail">
                                <h3 className="mb-5 border-bottom"><div className="anchorspace"></div>Diamond Detail</h3>
                                <div className="row">
                                    <div className="col-md-6">
                                        <p><strong>URL: </strong> https://api.diamondsoncall.com/feed/diamonddetail</p>
                                        <p>The use of this API is to Fetch the Full Details of the diamond. It provides you all the extra parameters, Heart & arrow images and aset images.</p>
                                        <p>You can use the same API for natural or Lab Grown Diamond.</p>
                                        <p>You can get data using either a Certificate number or stock ID.</p>
                                        <p className="callout callout-danger font-weight-bold">Note: You can fetch up to 25 diamonds at a time .</p>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="sticky-top" style={{top:'100px'}}>
                                            <KTCodeExample jsCode={diamondDetailsInput} beforeCodeTitle="INPUT CODE" classname="input-code"></KTCodeExample>
                                            <KTCodeExample jsCode={diamondDetailsResponse} beforeCodeTitle="RESPONSE DATA" classname="response-code"></KTCodeExample>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className='mt-10' id="Check-Availability">
                                <h3 className="mb-5 border-bottom"><div className="anchorspace"></div>Check Availability</h3>
                                <div className="row">
                                    <div className="col-md-6">
                                        <p><strong>URL: </strong> https://api.diamondsoncall.com/feed/checkavailability</p>
                                        <p>This API will allow you to check the availability of the diamond.</p>
                                        <p>In Response, you will also get Carat, Price per carat & total price.</p>
                                        <p>So you can verify the details before selling the diamond to your customer.</p>
                                        <p>You can request check availability API either using Certificate No. or Stock ID.</p>
                                        <p>You can request multiple diamonds in single a run.</p>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="sticky-top" style={{top:'100px'}}>
                                            <KTCodeExample jsCode={CheckAvailabilityInput} beforeCodeTitle="INPUT CODE" classname="input-code"></KTCodeExample>
                                            <KTCodeExample jsCode={CheckAvailabilityResponse} beforeCodeTitle="RESPONSE DATA" classname="response-code"></KTCodeExample>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className='mt-10' id="Hold">
                                <h3 className="mb-5 border-bottom"><div className="anchorspace"></div>Hold Diamond</h3>
                                <div className="row">
                                    <div className="col-md-6">
                                        <p><strong>URL: </strong> https://api.diamondsoncall.com/feed/holddiamond</p>
                                        <p> Hold Diamond API will allow you to hold diamonds directly through API with certificate number or stock id. </p>
                                        <p>You can request to hold multiple diamonds in a single run.</p>
                                        <p>You can request to hold with either Stock ID or Certificate Number.</p>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="sticky-top" style={{top:'100px'}}>
                                            <KTCodeExample jsCode={HoldInput} beforeCodeTitle="INPUT CODE" classname="input-code"></KTCodeExample>
                                            <KTCodeExample jsCode={HoldResponse} beforeCodeTitle="RESPONSE DATA" classname="response-code"></KTCodeExample>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className='mt-10' id="Confirm">
                                <h3 className="mb-5 border-bottom"><div className="anchorspace"></div>Confirm Diamond</h3>
                                <div className="row">
                                    <div className="col-md-6">
                                        <p><strong>URL: </strong>https://api.diamondsoncall.com/feed/confirmdiamond</p>
                                        <p>Confirm Diamond API will allow you to confirm diamonds directly through API with certificate number or stock id. </p>
                                        <p>You can request to confirm multiple diamonds in a single run.</p>
                                        <p>You can request to confirm with either Stock ID or Certificate Number</p>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="sticky-top" style={{top:'100px'}}>
                                            <KTCodeExample jsCode={ConfirmInput} beforeCodeTitle="INPUT CODE" classname="input-code"></KTCodeExample>
                                            <KTCodeExample jsCode={ConfirmResponse} beforeCodeTitle="RESPONSE DATA" classname="response-code"></KTCodeExample>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className='mt-10' id="Errors">
                                <h3 className="mb-5 border-bottom"><div className="anchorspace"></div>Errors</h3>
                                <div className="row">
                                    <div className="col-md-12">
                                        <p>Diamonds On Call Diamond Feed uses conventional HTTP response codes to indicate the success or failure of an API request. In general: Codes in the 2xx range indicate success. Codes in the 4xx range indicate an error that failed & Codes in the 5xx range indicate an error with Diamonds On Call's servers.</p>
                                        <table className="table table-responsive-sm table-bordered table-striped">
                                            <tbody>
                                                <tr>
                                                    <th className="text-left">Error Codes</th>
                                                    <td className="text-left"><strong>Description</strong></td>
                                                </tr>
                                                <tr>
                                                    <th className="text-left">200 - OK</th>
                                                    <td className="text-left"><p>Everything worked fine.</p></td>
                                                </tr>
                                                <tr>
                                                    <th className="text-left">400 - Bad Request</th>
                                                    <td className="text-left"><p>The request was unacceptable, often due to missing a required parameter.</p></td>
                                                </tr>
                                                <tr>
                                                    <th className="text-left"><strong>401 - Unauthorized</strong></th>
                                                    <td className="text-left"><p>No valid API key provided.</p></td>
                                                </tr>
                                                <tr>
                                                    <th className="text-left"><strong>404 - Not Found</strong></th>
                                                    <td className="text-left"><p>The requested resource doesn't exist.</p></td>
                                                </tr>
                                                <tr>
                                                    <th className="text-left"><strong>500, 502, 503, 504 - Server Errors</strong></th>
                                                    <td className="text-left"><p>Something went wrong on Diamonds On Call&rsquo;s end. </p></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <p>Following are the error messages displayed with the description for all API</p>
                                        <table className="table table-responsive-sm table-bordered table-striped ">
                                            <thead >
                                                <tr>
                                                    <th className="text-left">Error Types </th>
                                                    <th className="text-left">Description </th>
                                                    <th className="text-left">Error Messages Displayed </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="text-left bold">Authentication Failed</td>
                                                    <td className="text-left">
                                                        When API key is not added by the customer<br/><br/>
                                                        <code>{`{"apikey":"312645XXXX" }`}</code>
                                                    </td>
                                                    <td className="text-left">
                                                        <code>
                                                            {`{
                                                                "Success": false,
                                                                "Message": "Please enter api key."
                                                            }`}
                                                        </code>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td className="text-left">When the token is missing </td>
                                                    <td className="text-left">
                                                        <code>
                                                            {`{
                                                                "Message": "Unauthenticated."
                                                            }`}
                                                        </code>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td className="text-left"> When API key is invalid / Re-generate / Delete </td>
                                                    <td className="text-left">
                                                        <code>
                                                            {`{
                                                                "Success": false,
                                                                "Error": "Unauthorized"
                                                            }`}
                                                        </code>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td className="text-left"> When The API key doesn't have permissions to perform the request. </td>
                                                    <td className="text-left">
                                                        <code>
                                                        {`{
                                                            "Success": false,
                                                            "Error": "Your API temporarily suspended. 
                                                            Please contact to your sales representative."
                                                        }`}
                                                        </code>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left bold">Common Errors</td>
                                                    <td className="text-left">
                                                        When the certificate number or stock Id requested is empty<br /><br />
                                                        <code> {`array('certificate_no' => '','stock_id' => '')`} </code>
                                                    </td>
                                                    <td className="text-left">
                                                        <code>
                                                        {`{
                                                            "Success": false,
                                                            "Message": " Please enter either certificate number or stock id."
                                                        }`}
                                                        </code>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td className="text-left">
                                                        When you request both Certificate number and Stock ID<br /> <br />
                                                        <code>{` array('certificate_no' => '3242t52asd','stock_id' => '11322123')`}</code>
                                                    </td>
                                                    <td className="text-left">
                                                        <code>
                                                        {`{
                                                            "Success": false,
                                                            "Message": "Please enter either certificate number or stock id."
                                                        }`}
                                                        </code>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left bold">Something went wrong</td>
                                                    <td className="text-left">If during the process of requesting data, suddenly you lose connection or<br />
                                                    it stops for any other reason; it will show something went wrong. </td>
                                                    <td className="text-left">
                                                        <code>
                                                        {`{
                                                            "Success": false,
                                                            "Status": fail,
                                                            "Message": "Something Went Wrong"
                                                        }`}
                                                        </code>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </section>
                            <section className='mt-10' id="Test-API">
                                <h3 className="mb-5 border-bottom"><div className="anchorspace"></div>Test your Diamond feed API</h3>
                                <div className="row">
                                    <div className="col-md-12">
                                        <h5 className="bold mb-3">Activate Test Mode</h5>
                                        <p>You can Activate the test mode under API Setting, as marked in the image below. Once you activate the test mode, it will display you test API key for you to test. Test API key will expire in 7 days.</p>
                                        <figure className="text-center">
                                            <img src={toAbsoluteUrl("/media/General/test_api_ss1.png")} alt="test-api-ss" border="0" className="mx-auto" />
                                            {/* <figcaption className="pt-1"><i></i></figcaption> */}
                                        </figure>
                                        <h5 className="bold mb-3">Use following URL for API testing</h5>
                                        <p className="mb-1"><strong>Authorization: </strong> https://testapi.diamondsoncall.com/feed/login?apikey=20784809791623</p>
                                        <p className="mb-1"><strong>Natural Diamonds: </strong>https://testapi.diamondsoncall.com/feed/naturaldiamond</p>
                                        <p className="mb-1"><strong>Lab Grown Diamonds: </strong>https://testapi.diamondsoncall.com/feed/labgrowndiamond</p>
                                        <p className="mb-1"><strong>Confirm Diamonds: </strong>https://testapi.diamondsoncall.com/feed/confirmdiamond</p>
                                        <p className="mb-1"><strong>Hold Diamonds: </strong>https://testapi.diamondsoncall.com/feed/holddiamond</p>
                                        <p className="mb-1"><strong>Check Availability: </strong>https://testapi.diamondsoncall.com/feed/checkavailability</p>
                                        <p className="mb-4"><strong>Diamond Detail: </strong>https://testapi.diamondsoncall.com/feed/diamonddetail</p>
                                        <h5 className="bold mb-3">Get Testing Credentials</h5>
                                        <p>To check the data for test API, Click on “GET CREDETIALS” under API setting, this will send you email for credentials for login at https://testmode.diamondsoncall.com/. This will help you to see your hold and confirm diamonds when you process through API.</p>
                                        <h5 className="bold mb-3">Deactivate Test Mode</h5>
                                        <p>You can deactivate test API using same button in API setting, as marked in image below. Once you deactivate test API, you test API key and login credentials for testing will not work. You can activate again to regenerate the same </p>
                                        <figure className="text-center">
                                            <img src={toAbsoluteUrl("/media/General/test_api_ss1.png")} alt="test-api-ss" border="0" className="mx-auto" />
                                                {/* <figcaption className="pt-1"><i></i></figcaption> */}
                                        </figure>
                                    </div>
                                    
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
const AuthenticationInput = `
    curl --location --request POST 
        'https://api.diamondsoncall.com/feed/login?apikey=13842579XXXXXXXXXX'\\
    --header 'Content-Type: application/json'

`;

const AuthenticationResponse = `
{
    "Success": true,
    "Email": "test@gmail.com",
    "Token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiNDAzZmZiMjAwZDUxZDEwY2VhMjE3MTYxYzQwN2RjOWU3ZmNlZWFjZDcyNWRkZjMyMjRkNjdjOTc4MzA5MDljOTc3OGI1ZjM0YzgyNDI1ZTMiLCJpYXQiOiIxNjIwMTE3MzE3LjgxODk0NSIsIm5iZiI6IjE2MjAxMTczMTcuODE4OTQ4IiwiZXhwIjoiMTY1MTY1MzMxNy44MTY5OTgiLCJzdWIiOiIxNDI5Iiwic2NvcGVzIjpbXX0.FxPF8mOD-rojr04q9DtBCKDZPurEswQsVIAX4vepzOZuuF0VpUAE39JFaEQ1Qbt5SwGBYECv1aEjz98nvBxH0WqqEGeMoAIVdCT6M4BZF-_8-VZOM0312t6NX57fbBQBYV7gTIHAyJbvo8eUd9tGySYsXtyC_uzIOw_Mt-wRCswq41SMGqQIl05cCdCpvtXBT_1g8cCdaUad1l-JyXTGe24zrLJfoyKopULrzu3iIr0YSOxBljw221GPpk5pMzbG2_A1z9jmycqYYeu8MzIg_HHOYH2gzM0xuae0GKqifBojwQQDqCtX-sl8hLLnXFvFXosgikFUpsygRG0tGiLirQ0zjCEarLhEzywqOpDDtIXXiEs4_05deTTik-O0MKQDX9KkUG0cXde_bqJRAmCXl8t1Ibkm-XDk8FAOCFiL94A9WNAXJIWu5vwqn5Jp6GlSR1KlpRuaHn2wjvCmvOe7gWgmBDF4Y6Qvr95OuraL7mxCwdhBBxcTAve148BbSXRaMDmstquDCY6PB6l0Ir1_dDgKjvxdycEpUevWnp7tnf10wsfhqF3xVicbizeIOhz9KXLHsoyxyemVdFq3wj284lGUvHo14XLuO_XtKOrBT-nN1H64mS5wkmb18Q_YBSjd0puO3WpxdE35eeWlQ-qbBxrrSXcts1RfMR3u1ii3GA0"
}

`;

const NaturalLabInput = `
curl --location --request POST 'https://api.diamondsoncall.com/feed/naturaldiamond'\\
--header 'Accept: application/json' \\
--header 'Authorization: Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.=_FgAyD3tJObyPCgQdZ4Civvns_BXLr90A0pMS5_yaK1FExcXEJQZfXKhkG1ngtZqJfZdVwQLxiKouPoe_vzUsgmV5YuWJAZL_qp_NMwOhYZ5YOA37yo8JBGfHBdSqQ4Ou4' \\
--form 'stock_id=""' \\
--form 'shape="round,Princess,pear"' \\
--form 'color="D,F,M,QR"' \\
--form 'clarity="VS2,VVS1,I1"' \\
--form 'carat="0.70-7,1.05-1.05"' \\
--form 'cut="EX,VG"' \\
--form 'polish="EX,GD"' \\
--form 'symmetry="EX"' \\
--form 'fluorescence="NON,FNT"' \\
--form 'lab="GIA,IGI"' \\
--form 'shade=""' \\
--form 'milky=""' \\
--form 'eye_clean="YES"' \\
--form 'pricepercts_min=""' \\
--form 'pricepercts_max=""' \\
--form 'totalprice_min=""' \\
--form 'totalprice_max=""' \\
--form 'table_per_from="57"' \\
--form 'table_per_to="58"' \\
--form 'depth_per_from="61.2"' \\
--form 'depth_per_to="62.9"' \\
--form 'length_min=""' \\
--form 'length_max=""' \\
--form 'width_min=""' \\
--form 'width_max=""' \\
--form 'depth_min="3.5"' \\
--form 'depth_max="3.99"' \\
--form 'image_video=""' \\
--form 'fancy_color_diamond=""' \\
--form 'fancy_color=""' \\
--form 'fancy_intensity=""' \\
--form 'fancy_overtone=""' \\
--form 'sort_field="carat"' \\
--form 'sort_order="asc"' \\
--form 'currency="INR"'

`;

const NaturalLabResponse = `
{
    "Success": true,
    "Message": "DATA FOUND",
    "Total": 1,
    "CurrentPage": 1,
    "nextPageUrl": "",
    "PerPage": 100,
    "Data":
    [
        {		 
            "STOCK_ID": 162054,
            "SHAPE": "ROUND",
            "CARAT": "0.7",
            "COLOR": "M",
            "CLARITY": "I1",
            "CUT": "EX",
            "POLISH": "EX",
            "SYMMETRY": "EX",
            "FLUORESCENCE": "FNT",
            "LAB": "GIA",
            "WIDTH": "5.66",
            "LENGTH": "5.62",
            "DEPTH": "3.55",
            "DEPTH_PER": "62.9",
            "TABLE_PER": "57",
            "PRICE_PER_CTS": "691.98",
            "TOTAL_PRICE": "484.39",
            "SHADE": "light brown",
            "MILKY": "NO MILKY",
            "EYE_CLEAN": "",
            "COUNTRY": "INDIA",
            "IMAGE": "https://s3.us-east-2.amazonaws.com/docstone/6382378817.jpg",
            "VIDEO": "https://pro360video.com/video.php?cert=2387051060",
            "CURRENCY": "INR",
            "CURRENCY_RATE": "83.25"
        },
        {
            "STOCK_ID": 39331,
            "SHAPE": "ROUND",
            "CARAT": "0.7",
            "COLOR": "F",
            "CLARITY": "VS2",
            "CUT": "EX",
            "POLISH": "EX",
            "SYMMETRY": "EX",
            "FLUORESCENCE": "NON",
            "LAB": "GIA",
            "WIDTH": "5.67",
            "LENGTH": "5.63",
            "DEPTH": "3.55",
            "DEPTH_PER": "62.9",
            "TABLE_PER": "57",
            "PRICE_PER_CTS": "3826.3536",
            "TOTAL_PRICE": "2678.45",
            "SHADE": "none",
            "MILKY": "NO MILKY",
            "EYE_CLEAN": "YES",
            "COUNTRY": "INDIA",
            "IMAGE": " https://s3.us-east-2.amazonaws.com/dcsone/63325235317.jpg ",
            "VIDEO": "",
            "CURRENCY": "INR",
            "CURRENCY_RATE": "83.25"
        },
        {
            "STOCK_ID": 162988,
            "SHAPE": "ROUND",
            "CARAT": "0.71",
            "COLOR": "F",
            "CLARITY": "VVS1",
            "CUT": "EX",
            "POLISH": "EX",
            "SYMMETRY": "EX",
            "FLUORESCENCE": "NON",
            "LAB": "GIA",
            "WIDTH": "5.7",
            "LENGTH": "5.66",
            "DEPTH": "3.54",
            "DEPTH_PER": "62.3",
            "TABLE_PER": "58",
            "PRICE_PER_CTS": "4458.23145",
            "TOTAL_PRICE": "3165.34",
            "SHADE": "none",
            "MILKY": "NO MILKY",
            "EYE_CLEAN": "YES",
            "COUNTRY": "INDIA",
            "IMAGE": "",
            "VIDEO": "https://pro360video.com/video.php?cert=7372708155",
            "CURRENCY": "INR",
            "CURRENCY_RATE": "83.25"
        }, 
    ]
}
`;

const FancyColorInput = `
curl --location --request POST 'https://api.diamondsoncall.com/feed/labgrowndiamond'\\
    --header 'Accept: application/json' \\
    --header 'Authorization: Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.=_FgAyD3tJObyPCgQdZ4Civvns_BXLr90A0p-MS5_yaK1FExcXEJQZfXKhkG1ngtZqJfZdVwQLxiKouPoe_vzUs-gmV5YuWJAZL_qp_NMwOhYZ5YOA37yo8JBGfHBdSqQ4Ou4' \\
    --form 'fancy_color_diamond="yes"' \\
    --form 'fancy_color="blue"' \\
    --form 'fancy_intensity="fancy light"' \\
    --form 'fancy_overtone="bluish"' \\
    --form 'currency="INR"'

`;

const FancyColorResponse = `
{
    "Success": true,
    "Message": "DATA FOUND",
    "Total": 1,
    "CurrentPage": 1,
    "nextPageUrl": "",
    "PerPage": 100,
    "Data": 
    [
        {
            "STOCK_ID": 10138500,
            "SHAPE": "ROUND",
            "CARAT": "2.01",
            "FANCY_COLOR": "BLUE",
            "FANCY_INTENSITY": "FANCY LIGHT",
            "FANCY_OVERTONE": "BLUISH",
            "CLARITY": "SI1",
            "CUT": "",
            "POLISH": "VG",
            "SYMMETRY": "GD",
            "FLUORESCENCE": "NON",
            "LAB": "IGI",
            "WIDTH": "8.15",
            "LENGTH": "8.05",
            "DEPTH": "4.86",
            "DEPTH_PER": "59.9",
            "TABLE_PER": "65.5",
            "PRICE_PER_CTS": "1224",
            "TOTAL_PRICE": "2460.24",
            "SHADE": "",
            "MILKY": "NO MILKY",
            "EYE_CLEAN": "YES",
            "COUNTRY": "INDIA",
            "IMAGE": "",
            "VIDEO": "https://pro360video.com/labgrown.php?cert=457061754",
            "CURRENCY": "INR",
            "CURRENCY_RATE": "83.25"
        }
    ]
}

`;

const diamondDetailsInput = `
curl --location --request POST 'https://api.diamondsoncall.com/feed/diamonddetail' \\
--header 'Accept: application/json' \\
--header 'Authorization: Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiZTBjZTg0NjJhNDAyYmQ3MWU5MWE0YTM1YTRmMmEzZTBlXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'\\ --form 'certificate_no="459192902,310857853"' \\
--form 'stock_id=""' \\
--form 'currency="INR"'

`;

const diamondDetailsResponse = `
{
    "Success": true,
    "Data": [
        {
            "Message": "Diamond found.",
            "DIAMOND_TYPE": "Lab-Grown",
            "STOCK_ID": 10146304,
            "SHAPE": "ROUND",
            "CARAT": "0.3",
            "COLOR": "E",
            "CLARITY": "VS1",
            "CUT": "EX",
            "POLISH": "EX",
            "SYMMETRY": "EX",
            "FLUORESCENCE": "NON",
            "LAB": "IGI",
            "CERTIFICATE_NO": "459192902",
            "WIDTH": "4.27",
            "LENGTH": "4.24",
            "DEPTH": "2.67",
            "DEPTH_PER": "62.8",
            "TABLE_PER": "59",
            "CROWNANGLE": "34.9",
            "CROWNHEIGHT": "0",
            "PAVILIONHEIGHT": "0",
            "PAVILIONANGLE": "41.4",
            "PRICE_PER_CTS": "446.76",
            "TOTAL_PRICE": "134.03",
            "MILKY": "",
            "SHADE": "",
            "EYE_CLEAN": "Yes",
            "LOCATION": "INDIA",
            "CULET": "",
            "GIRDLE": "4.46",
            "GIRDLE_CONDITION": "",
            "KEY_TO_SYMBOL": "",
            "RATIO": "0.99",
            "IMAGE": "",
            "VIDEO": "https://pro360video.com/labgrown.php?cert=459192902",
            "HEART_IMAGE": "",
            "ARROW_IMAGE": "",
            "ASSET_IMAGE": "",
            "CURRENCY": "INR",
            "CURRENCY_RATE": "83.25"
        },
        {
            "Message": "Diamond found.",
            "DIAMOND_TYPE": "Natural",
            "STOCK_ID": 156437,
            "SHAPE": "ROUND",
            "CARAT": "0.18",
            "COLOR": "F",
            "CLARITY": "SI1",
            "CUT": "VG",
            "POLISH": "GD",
            "SYMMETRY": "GD",
            "FLUORESCENCE": "NON",
            "LAB": "IGI",
            "CERTIFICATE_NO": "310857853",
            "WIDTH": "3.67",
            "LENGTH": "3.7",
            "DEPTH": "2.13",
            "DEPTH_PER": "57.8",
            "TABLE_PER": "64",
            "CROWNANGLE": "33.3",
            "CROWNHEIGHT": "12",
            "PAVILIONHEIGHT": "41.5",
            "PAVILIONANGLE": "40.1",
            "PRICE_PER_CTS": "1055.23",
            "TOTAL_PRICE": "189.94",
            "MILKY": "NO MILKY",
            "SHADE": "",
            "EYE_CLEAN": "",
            "LOCATION": "",
            "CULET": "",
            "GIRDLE": "0",
            "GIRDLE_CONDITION": "",
            "KEY_TO_SYMBOL": "CRYSTAL, FEATHER, CLOUD",
            "RATIO": "1.01",
            "IMAGE": "",
            "VIDEO": "",
            "HEART_IMAGE": "",
            "ARROW_IMAGE": "",
            "ASSET_IMAGE": "",
            "CURRENCY": "INR",
            "CURRENCY_RATE": "83.25"
        }
    ]
}


`;

const CheckAvailabilityInput = `
curl --location --request POST 'https://api.diamondsoncall.com/feed/checkavailability' \\
--header 'Accept: application/json' \\
--header 'Authorization: Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiZTBjZTg0NjJhNDAyYmQ3MWU5MXXXXXXXXXXXXXXXXXXXXXXXXXHCh0tJqbWFzH3wd_lwqtJoaymGxCTq3fpU3h66NQxtrIarLLJ0ngAb1fOFTbRSyvBS6ByV_UfuW_SvOG3OwA8qKGnd6Vuy_qb- '\\
--form 'certificate_no="291762358,301870002"' \\
--form 'stock_id=""' \\
--form 'currency="INR"'

`;

const CheckAvailabilityResponse = `
{
    "Success": true,
    "Message": "DATA FOUND.",
    "Data": [
        {
            "STATUS": "Available",
            "DIAMOND_TYPE": "Natural",
            "STOCK_ID": 156426,
            "CARAT": "0.18",
            "CERTIFICATE_NO": "291762358",
            "PRICE_PER_CTS": "1023.93",
            "TOTAL_PRICE": "184.31",
            "CURRENCY": "INR",
            "CURRENCY_RATE": "83.25"
        },
        {
            "STATUS": "Available",
            "DIAMOND_TYPE": "Lab-Grown",
            "STOCK_ID": 10146723,
            "CARAT": "0.23",
            "CERTIFICATE_NO": "301870002",
            "PRICE_PER_CTS": "663.51",
            "TOTAL_PRICE": "152.61",
            "CURRENCY": "INR",
            "CURRENCY_RATE": "83.25"
        }
    ]
}

`;

const HoldInput = `
curl --location --request POST 'https://api.diamondsoncall.com/feed/holddiamond' \\
    --header 'Accept: application/json' \\
    --header 'Authorization: Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiZTBjZTg0NjJhNDAyYmQ3MWU5MXXXXXXXXXXXXXXXXXXXXXXXXXHCh0tJqbWFzH3wd_lwqtJoaymGxCTq3fpU3h66NQxtrIarLLJ0ngAb1fOFTbRSyvBS6ByV_UfuW_SvOG3OwA8qKGnd6Vuy_qb- --form 'certificate_no=""' \\
    --form 'stock_id="60697,72474,103707,154815"'

`;

const HoldResponse = `
{
    "Success": true,
    "Data": [
        {
            "Message": "Diamond(s) not available to hold.",
            "STOCK_ID": 60697
        },
        {
            "Message": "Diamond(s) added in Hold Diamond List.",
            "STOCK_ID": 72474
        },
        {
            "Message": "Diamond(s) already added in Hold List.",
            "STOCK_ID": 103707
        },
        {
            "Message": "Diamond(s) not allowed to hold.",
            "STOCK_ID": 154815
        }
    ]
}

`;

const ConfirmInput = `
curl --location --request POST 'https://api.diamondsoncall.com/feed/confirmdiamond' \\
--header 'Accept: application/json' \\
--header 'Authorization: Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiZTBjZTg0NjJhNDAyYmQ3MWU5MXXXXXXXXXXXXXXXXXXXXXXXXXHCh0tJqbWFzH3wd_lwqtJoaymGxCTq3fpU3h66NQxtrIarLLJ0ngAb1fOFTbRSyvBS6ByV_UfuW_SvOG3OwA8qKGnd6Vuy_qb-'\\
--form 'certificate_no="301870073,301870026"' \\
--form 'stock_id=""'

`;

const ConfirmResponse = `
{
    "Success": true,
    "Data": [
        {
            "Message": "Diamond added in confirm goods.",
            "CERTIFICATE_NO": "301870073"
        },
        {
            "Message": "Diamond already added in confirm goods.",
            "CERTIFICATE_NO": "301870026"
        }
    ]
}

`;