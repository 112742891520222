/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from "react";
import { FaWhatsapp, FaEnvelope } from "react-icons/fa";
import axios from "axios";
import { NODE_API_URL } from "../../../../env_config";
export const SalesExecutiveTile = (props) => {
    const userData = JSON.parse(localStorage.getItem("userData"))
    const [salesperson,setSalesPerson] = useState(null)
    useEffect(() => {
        axios({
            method:"POST",
            url:`${NODE_API_URL}/IVP/fetchSalesPersonDetail`,
            headers: { "Content-Type": "application/json" },
            data:{
                user_id:userData.CustomerId
            }
        })
        .then((response) => {
            if(response && response.data && response.data.data){
                console.log(response.data.data)
                setSalesPerson(response.data.data)
            }
        })
    },[])
  
    return (
        <>
            <div className={`card card-custom gutter-b bg-white`}>
                <div className="card-body salesExecutiveTile">
                    <span className="small_title">{props.language.DASHBOARD_SALESEXECUTIVE}</span>
                    <div className="d-flex align-items-center mt-5">
                        <div className="mr-8">
                            <img
                                width="86"
                                className="rounded-circle bg-light p-2"
                                src="/media/General/usericon.png"
                                alt="user img"
                            />
                        </div>
                        <div className="natTab">
                            <span className="sales_exc_name text-capitalize font-size-h2 font-weight-bolder text-pripary ">
                                {salesperson && salesperson.fname ? salesperson && salesperson.fname : null}{" "}
                                {salesperson && salesperson.lname ? salesperson && salesperson.lname : null}
                            </span>
                            <span className="contact_card_img d-block mt-2">
                                <FaWhatsapp className="text-success font-size-h6" />
                                <a
                                    className="ml-3 text-primary font-size-h6"
                                    href={`https://wa.me/${
                                        salesperson && salesperson.whatsapp ? salesperson && salesperson.whatsapp : null
                                    }`}
                                >
                                    {salesperson && salesperson.whatsapp ? salesperson && salesperson.whatsapp : null}
                                </a>
                            </span>
                            <span className="contact_card_img d-flex align-items-center mt-1">
                                <FaEnvelope className="text-danger font-size-h6" />
                                <a
                                    className="ml-3 text-primary mt-0 font-size-h6 text-break"
                                    href={`mailto:${
                                        salesperson && salesperson.mail
                                            ? salesperson && salesperson.mail ===
                                              "admin@diamondsoncall.com"
                                                ? "info@diamondsoncall.com"
                                                : salesperson && salesperson.mail
                                            : null
                                    }`}
                                >
                                    {salesperson && salesperson.mail
                                        ? salesperson && salesperson.mail ===
                                          "admin@diamondsoncall.com"
                                            ? "info@diamondsoncall.com"
                                            : salesperson && salesperson.mail
                                        : null}
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
