import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
export const SwalError = (props) => {
    const hideModel = () => {
        props.onHide();
    };
    const closeModel = () => {
        props.onClose();
    };
    const responseClose = (props) => {
        props.responseOnOk()
    };
    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.onClose}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Body className="text-center my-6">
                    <div className="screenAlert-icon screenAlert-error animate">
                        <span className="screenAlert-x-mark">
                            <span className="screenAlert-line screenAlert-left animateXLeft"></span>
                            <span className="screenAlert-line screenAlert-right animateXRight"></span>
                        </span>
                        <div className="screenAlert-placeholder"></div>
                        <div className="screenAlert-fix"></div>
                    </div>
                    <p className="font-size-h4 font-weight-bold my-10">{props.message ? props.message : "Error"}</p>
                   {props.onHide ? <Button
                        variant="secondary"
                        onClick={() => hideModel(props)}
                        className="mr-4"
                    >
                        cancel
                    </Button> : ""}
                    {props.responseOnOk?<Button className="px-5"
                        variant="primary"
                        onClick={() => {props.responseOnOk ? responseClose(props): closeModel(props) }}
                    >
                        ok
                    </Button>:""}
                    {props.closeBtn?<Button className="px-5"
                        variant="primary"
                        onClick={() => {closeModel(props) }}
                    >
                        close
                    </Button>:""}
                    
                </Modal.Body>
            </Modal>
        </div>
    );
};
