import React, { useState ,useEffect} from 'react';
import {
  Button,
  Grid,
  CircularProgress
} from '@material-ui/core';
import { BsArrowLeftShort } from 'react-icons/bs';
import { Formik, Form } from 'formik';
import axios from "axios"
import Cookies from "js-cookie"
import ThemeFrom from './CcmodeSetupNew/FormEdit/ThemeFrom';
// import ReviewOrder from './ReviewOrder';
// import FormSuccess from '../../ccmode/CcmodeSetting/FormSuccess/FormSuccess';
import { NODE_API_URL,BASE_URL } from '../../../env_config';
import validationSchemaEdit from './CcmodeSetupNew/FormModel/validationSchemaEdit';
import validationSchemaEditTheme from './CcmodeSetupNew/FormModel/validationSchemaEditTheme';
import kycFormModel from './CcmodeSetupNew/FormModel/kycFormModel';
import {fetchData} from './CcmodeSetupNew/FormModel/formInitialValues';
import useStyles from './CcmodeSetupNew/styles';
import { Alert } from 'react-bootstrap';

const steps = [];
const { formId, formField } = kycFormModel;


export default function CcmodeThemeSetting(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchemaEditTheme[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const [companylogo,setCompanyLogo] = useState()
  const [backgroundimage,setBackGroundImage] = useState()
  const [initialValues,setInitialValues] = useState({})
  const [openpopup,setOpenPopUp] = useState(false)
  useEffect(() => {
    // fetchData(props.userinfo.mail).then(values => {
      // console.log(values,"fetchData")
      // setInitialValues(values)
    // })
  },[])
  // console.log(initialValues,"initialValues")
  function _sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function _submitForm(values, actions) {
    // console.log("Submit")
  }

  function _handleSubmit(values, actions) {
    console.log(values,"valuesvalues")
    actions.setSubmitting(false);
    const formData = new FormData();
    if(companylogo){
      formData.append("comapny_logo", companylogo[0])
    }
    if(values.white_label_logo){
      formData.append("white_label_logo", values.white_label_logo)
    }
    if(backgroundimage){
      formData.append("backgroundimage", backgroundimage[0])
    }
    if(values.background_image){
      formData.append("background_image", values.background_image)
    }
    if(props.userinfo.id){
      formData.append("CustomerId", props.userinfo.id)
    }
    if(values.theme){
      formData.append("Theme", values.theme)
    }
    if(values.wl_primarycolor){
      formData.append("NaturalPrimaryColor", values.wl_primarycolor)
    }
    if(values.n_fontcolor){
      formData.append("NaturalFontColor", values.n_fontcolor)
    }
    if(values.lab_primary){
      formData.append("LabPrimaryColor", values.lab_primary)
    }
    if(values.lab_fontcolor){
      formData.append("LabFontColor", values.lab_fontcolor)
    }
    if(values.nav_bar_color){
      formData.append("nav_bar_color", values.nav_bar_color)
    }
    if(values.timeZone){
      formData.append("timeZone", values.timeZone)
    }
    if (values.hideCertificateNumber >= 0) {
      formData.append("hideCertificateNumber", values.hideCertificateNumber ? 1 : 0);
    }
    if (values.hideEstimatedShippingDays >= 0) {
      formData.append("hideEstimatedShippingDays", values.hideEstimatedShippingDays ? 1 : 0);
    }
    if(values.background_color){
      formData.append("background_color", values.background_color)
    }
    if(values.nav_bar_font){
      formData.append("nav_bar_font", values.nav_bar_font)
    }
    formData.append("displayName", values.displayName || "");

    axios({
      method: "POST",
      url: `${NODE_API_URL}/IVP/insert-update-ccmode-setting`,
      headers: {
        'Content-Type': 'multipart/form-data',
        "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
      },
      data: formData,
    }).then(res => {
        // console.log(res,"RESPONSEEEE")
        actions.setSubmitting(false);
        if(res.data.success){
          // setActiveStep(activeStep + 1);
          // alert("Form Submitted")
          setOpenPopUp(true)
          setTimeout(() => {
            const container = document.getElementById('themesetting');
            if (container) {
              container.scrollIntoView({ top: 0, left: 0, behavior: 'smooth' });
            }
          }, 100); // Adjust the delay time as needed
          setTimeout(()=>{
            setOpenPopUp(false)
          },5000)
        }
    }).catch(error => {
      if(error?.response?.data?.message === "UnAuthorized!"){
        var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        if(cookies[i] && !cookies[i].includes("view")){
          var equals = cookies[i].indexOf("=");
          var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
          document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
      }
      
      window.location.replace(`${BASE_URL}/logout`);
    }
    })
  }

  return (
    <React.Fragment>
          {openpopup?
            <div>
              <Alert variant="success">
                  Your Client-Centric Mode Theme Setting Updated Successfully..
              </Alert>
              <Alert variant="success">
                  Please login again in CC-Mode to see the changes..
              </Alert>
            </div>
          :""}

      <React.Fragment>
      <div className="row">
          <div className="col-12 mb-4 setting-title d-flex justify-content-between pb-5 border-bottom" >
            CCMODE THEME SETTING
            <span className="cursor-pointer text-hover-primary"  onClick={() =>window.history.go(-1)}><BsArrowLeftShort /> Back to dashboard</span>
          </div>
          <div className="col-12">
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={currentValidationSchema}
                onSubmit={_handleSubmit}
            >
                {({ isSubmitting,setFieldValue, values }) => (
                <Form id={formId}>
                    <ThemeFrom
                      formField={formField}
                      values={values}
                      setFieldValue={setFieldValue}
                      setCompanyLogo={setCompanyLogo}
                      setBackGroundImage={setBackGroundImage}
                      language={props.language}
                      ccmodesetting={props.ccmodesetting}
                      displayName={props.displayName}
                    />
                    <Grid className="mt-10 sticky-top" container justify={"flex-end"} alignItems="center"  style={{bottom:"0px"}}>
                    <div className={classes.wrapper}>
                        <Button
                          disabled={isSubmitting}
                          type="submit"
                          variant="contained"
                          color="primary"
                          className={classes.button}
                        >
                          {props.language.SETTING_PASSWORD_SAVE}
                        </Button>
                        {isSubmitting && (
                          <CircularProgress
                              size={24}
                              className={classes.buttonProgress}
                          />
                        )}
                    </div>
                    </Grid>
                </Form>
                )}
            </Formik>
          </div>
      </div>
      </React.Fragment>
    </React.Fragment>
  );
}
