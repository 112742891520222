import React from "react";
import moment from "moment-timezone";

function numberWithCommas(x) {
    return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const MockDocTableColumn = (language,userinfo) => [
    {
        Header: language.INVOICE_INVOICE_NO,
        accessor: (d) => (
            <>
                <span>{d.invoice_country === "IND-EXP"?d.export_no:d.invoicenumber}</span>
                <br />
                <span className="text-muted small">
                    {/* {d.date} */}
                    { d.date ? moment.tz(d.date, userinfo.timezone ? userinfo.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone).format("YYYY-MM-DD HH:mm:ss") : "-"}
                </span>
            </>
        ),
        className: "align-middle text-center",
    },
    {
        Header:language.INVOICE_TOTALPCS,
        className:"text-center align-middle mr-3",
        accessor: "total_pcs",
    },
    {
        Header:language.INVOICE_TOTALCARAT,
        className:"text-center align-middle mr-3",
        accessor: "total_carat",
        Cell: (row) => {
            return (
                <>
                    <span>{row.value}</span>
                </>
            );
        },
    },
    {
        Header: language.INVOICE_OURPRICE,
        accessor: (d) => (
            <>
                <span className="font-weight-bold">
                    ₹{numberWithCommas(parseFloat(d["Price(INR)"]))}
                </span>{" "}
                <br />
                {/* <span className="text-muted small">
                    ₹/CT {numberWithCommas(parseFloat(d["INR_CT"]))}
                </span> */}
            </>
        ),
        className: "text-center pr-12 align-middle",
    },
    {
        Header: language.INVOICE_STATUS,
        accessor: "shipping_status",
        className: "text-center align-middle",
        Cell: (row) => {
            return (
                <div>
                    <div
                        className={`label ${
                                  row.value === 1 ? "label-primary"
                                : row.value === 0 ? "label-warning"
                                : row.value === "Return" ? "label-danger" 
                                : ""
                        } label-inline  mr-2`}
                    >
                        {row.value === 0?"Pending":row.value === 1?"Shipped":"Return"}
                    </div>
                </div>
            );
        },
    },
    {
        Header:language.INVOICE_CARRIER,
        className:"text-center align-middle mr-3",
        accessor: "shippingNew",
    },
];
