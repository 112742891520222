/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {DiamondDetails} from "./DiamondDetails"
import { FaCheck } from 'react-icons/fa';
import { BiShieldQuarter } from 'react-icons/bi';
import { toAbsoluteUrl } from "../../_helpers";
import StockAPI from "./StockAPI.json"

export const Payment = (props) => {

    return (
        <>
            <div className="row">
                <div className="col-md-8">
                    <div className="card custom-card gutter-b">
                        <div className="card-header border-bottom-0 pb-2">
                            <div className="card-title font-weight-bold mb-0 font-size-h4">BILLING INFORMATION <FaCheck className="text-primary ml-2" /></div>
                        </div>
                        <div className="card-body pt-4">
                            <p>DIAMONDS ON CALL</p>
                            <p>Priyanka Atodariya,  504  Wiseman Street, Knoxville, TN,  <br />Tennesse-37909  United States</p>
                            <p><span><b>E</b>: priyanaktodariya@gmail.com</span>  <span className="ml-md-10"><b>M</b>: 8866894500</span></p>
                        </div>
                    </div>
                    <div className="card custom-card gutter-b">
                        <div className="card-header border-bottom-0 pb-2">
                            <div className="card-title font-weight-bold mb-0 font-size-h4">DELIVERY INFORMATION <FaCheck className="text-primary ml-2" /></div>
                        </div>
                        <div className="card-body pt-4">
                            <p>DIAMONDS ON CALL</p>
                            <p>Priyanka Atodariya,  504  Wiseman Street, Knoxville, TN,  <br />Tennesse-37909  United States</p>
                            <p><span><b>E</b>: priyanaktodariya@gmail.com</span>  <span className="ml-md-10"><b>M</b>: 8866894500</span></p>
                        </div>
                    </div>
                    <div className="card custom-card gutter-b">
                        <div className="card-header border-bottom-0 pb-2">
                            <div className="card-title font-weight-bold mb-0 font-size-h4">SHIPPING METHOD</div>
                        </div>
                        <div className="card-body pt-4">
                            <p>UPS</p>
                        </div>
                    </div>
                    <div className="card custom-card gutter-b">
                        <div className="card-header border-bottom-0 pb-2">
                            <div className="card-title font-weight-bold mb-0 font-size-h4">PAYMENT METHOD</div>
                        </div>
                        <div className="card-body pt-4">
                            <label className="payment_method" >
                                <div className="text-center">
                                    <input type="radio" name="paymenyType" value="" className="payment_method_input" />
                                    <div className="payment_method_box">
                                        <img className="img-fluid" alt="dummy_card" src={toAbsoluteUrl(`/media/General/dummy_card.png`)} />
                                    </div>
                                    <span className="font-weight-bold">Credit Card</span>
                                </div>
                            </label>
                            <label className="payment_method" >
                                <div className="text-center">
                                    <input type="radio" name="paymenyType" value="" className="payment_method_input" />
                                    <div className="payment_method_box">
                                        <img className="img-fluid" alt="paypal" src={toAbsoluteUrl(`/media/General/paypal.png`)} />
                                    </div>
                                    <span className="font-weight-bold">Paypal</span>
                                </div>
                            </label>
                            <label className="payment_method" >
                                <div className="text-center">
                                    <input type="radio" name="paymenyType" value="" className="payment_method_input" />
                                    <div className="payment_method_box">
                                        <img className="img-fluid" alt="apple_pay" src={toAbsoluteUrl(`/media/General/Apple_Pay.png`)} />
                                    </div>
                                    <span className="font-weight-bold">Apple pay</span>
                                </div>
                            </label>
                            <label className="payment_method" >
                                <div className="text-center">
                                    <input type="radio" name="paymenyType" value="" className="payment_method_input" />
                                    <div className="payment_method_box">
                                        <img className="img-fluid" alt="bankwire" src={toAbsoluteUrl(`/media/General/bankwire.png`)} />
                                    </div>
                                    <span className="font-weight-bold">Bank Wire</span>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className="card custom-card gutter-b">
                        <div className="card-header border-bottom-0 pb-2">
                            <div className="card-title font-weight-bold mb-0 font-size-h4">Diamonds Details  <FaCheck className="text-primary ml-2" /></div>
                        </div>
                        <div className="card-body pt-4">
                            <DiamondDetails data={StockAPI} />
                        </div>
                    </div> 
                </div>
                <div className="col-md-4">
                    <div className="row">
                        <div className="col-12">
                            <div className="card custom-card gutter-b">
                                <div className="card-body">
                                   <div className="row">
                                        <div className="col-12 mb-2">
                                            <div className="font-weight-bold mb-0 font-size-h4">PRICE DETAILS<span className="small ml-2">({StockAPI.length} items)</span></div>
                                        </div>
                                        <div className="col-12 d-flex mt-2 justify-content-between font-weight-bold">
                                            <div> Item Total: </div>
                                            <div> $306,100.00 </div>
                                        </div>
                                        <div className="col-12 d-flex mt-2 justify-content-between font-weight-bold">
                                            <div> Sub Total: </div>
                                            <div> $306,100.00 </div>
                                        </div>
                                        <div className="col-12 d-flex mt-2 justify-content-between font-weight-bold">
                                            <div> Discounts: </div>
                                            <div> $13.42 </div>
                                        </div>
                                        <div className="col-12 d-flex mt-2 justify-content-between font-weight-bold">
                                            <div> Shipping charges: </div>
                                            <div> $100.00 </div>
                                        </div>
                                   </div>
                                </div>
                                <div className="card-footer py-5">
                                    <div className="row">
                                        <div className="col-12 d-flex justify-content-between font-weight-bold">
                                            <div className="mb-0 font-size-h5">Total</div>
                                            <div className="mb-0 font-size-h5">$144,452.00</div>
                                        </div>
                                   </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 text-center">
                          <button className="btn btn-primary px-md-30 px-20 font-weight-bold">Continue</button>                                      
                    </div>
                    <div className="col-12 text-center text-muted mt-4">
                        <BiShieldQuarter className="mr-2 font-size-h4" />safe and secure payment
                    </div>
                    <div className="col-12 mt-4 mt-md-10">
                        <a href="#">How are delivery costs calculated?</a><br />
                        <a href="#">Why I've to pay 20% Payment?</a><br />
                        <a href="#">Why we take time to approve diamond?</a>
                    </div>
                </div>
            </div>
        </>
    );
};
