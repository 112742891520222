import { Radio, Switch, TextField } from '@material-ui/core';
import React from 'react';
import { LiaAwardSolid, LiaMoneyBillWaveSolid } from 'react-icons/lia';
// import './CustomSwitch.css';
import { AiOutlineClose } from "react-icons/ai";

const ShareSetting = ({close}) => {
    const [selectedValue, setSelectedValue] = React.useState('current_price');

    function handleChange(event) {
        setSelectedValue(event.target.value);
    }


  return (
    <>
        <div className='d-flex justify-content-between'>
            <div className='font-size-h4 d-flex flex-column align-items-start'>
                <span>Share</span>
                <span className='small'>Lorem ipsum share popup</span>
            </div>
        <button className='btn btn-sm btn-clear btn-hover-bg-light font-size-h4 mr-2' onClick={close}><AiOutlineClose /></button>
      </div>
        <div className="row mt-5">
            <div className="col-12 mt-3 d-flex justify-content-between">
                <div className='d-flex align-items-start'>
                    <div className='bg-success-o-30 text-success border-success mt-1 share-icon'><LiaAwardSolid /></div>
                    <div>
                        <div className='font-size-lg'>Certificate</div>
                        <div className="text-muted mb-3">would you like to show certificate details</div>
                    </div>
                </div>
                <Switch
                    value={1}
                    color="primary"
                    // disabled={disabled}
                />
            </div>
            <div className='col-12'>
                <hr  className='w-75 m-auto'/>
                <div className='font-weight-bold mt-4'>Price settings</div>
            </div>
            <div className="col-12 mt-3 d-flex justify-content-between">
                <div className='d-flex align-items-start'>
                    <div className='bg-primary-o-30 text-primary border-primary mt-1 share-icon'><LiaMoneyBillWaveSolid /></div>
                    <div>
                        <div className='font-size-lg'>Current price</div>
                        <div className="text-muted mb-3">would you like to show current price with details</div>
                    </div>
                </div>
                <Radio
                    checked={selectedValue === 'current_price'}
                    onChange={handleChange}
                    value="current_price"
                    name="priceRadio"
                    inputProps={{ 'aria-label': 'Current Price' }}
                />
            </div>
            <hr  className='w-75 m-auto'/>
            <div className="col-12 mt-3 d-flex justify-content-between">
                <div className='d-flex align-items-start'>
                    <div className='bg-danger-o-30 text-danger border-danger mt-1 share-icon'><LiaMoneyBillWaveSolid /></div>
                    <div>
                        <div className='font-size-lg'>No price</div>
                        <div className="text-muted mb-3">would you like to show no price with details</div>
                    </div>
                </div>
                <Radio
                    checked={selectedValue === 'no_price'}
                    onChange={handleChange}
                    value="no_price"
                    name="priceRadio"
                    inputProps={{ 'aria-label': 'No Price' }}
                />
            </div>
            <hr  className='w-75 m-auto'/>

            <div className="col-12 mt-3 d-flex justify-content-between">
                <div className='d-flex align-items-start'>
                    <div className='bg-warning-o-30 text-warning border-warning mt-1 share-icon'><LiaMoneyBillWaveSolid /></div>
                    <div>
                        <div className='font-size-lg'>Markup price</div>
                        <div className="text-muted mb-3">would you like to show markup price with details</div>
                    </div>
                </div>
                <Radio
                    checked={selectedValue === 'markup_price'}
                    onChange={handleChange}
                    value="markup_price"
                    name="priceRadio"
                    inputProps={{ 'aria-label': 'Markup Price' }}
                />
            </div>
            {/* <hr  className='w-75 m-auto'/> */}

            <div className='col-12 mt-5'>
                <div className={`markup-price-div form-group ${selectedValue === 'markup_price' ? 'show' : ''}`}>
                    <div className='font-weight-bold mb-4'>More setting</div>
                    <TextField
                        type='number'
                        label="Enter Markup Value"
                        id="markupInput"
                        name="markupInput"
                        variant="outlined"
                        placeholder="Enter markup value"
                        fullWidth
                    />
                </div>
            </div>

        </div>
    </>
  );
};

export default ShareSetting;
