import React, { useState } from "react";
import { Modal, Button,Carousel } from "react-bootstrap";
import { toAbsoluteUrl } from "../../_helpers";
export const ImagePreview = (props) => {
    const hideModel = () => {
        props.onHide();
    };
    const closeModel = () => {
        props.onClose();
    };
    const responseClose = (props) => {
        props.responseOnOk()
    };
    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.onClose}
                backdrop="static"
                keyboard={false}
                centered
                size="xl"
            >
                <Modal.Body className="text-center img-prev-modal my-6">
                    {/* <img alt={props.image} src={toAbsoluteUrl(`/media/image/${props.image}.png`)} className="img-fluid"/> */}
                    <Carousel>
                        <Carousel.Item>
                            <img
                                className="img-prev"
                                src={toAbsoluteUrl(`/media/General/iframe/${props.image}/01.jpg`)}
                                alt={(`${props.image}_1`)}
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="img-prev"
                                src={toAbsoluteUrl(`/media/General/${props.image}/02.jpg`)}
                                alt={(`${props.image}_2`)}
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="img-prev"
                                src={toAbsoluteUrl(`/media/General/${props.image}/03.jpg`)}
                                alt={(`${props.image}_3`)}
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="img-prev"
                                src={toAbsoluteUrl(`/media/General/${props.image}/04.jpg`)}
                                alt={(`${props.image}_4`)}
                            />
                        </Carousel.Item>
                    </Carousel>
                    <p className="font-size-h4 font-weight-bold my-10">{props.message ? props.message : ""}</p>
                   {props.onHide ? <Button
                        variant="secondary"
                        onClick={() => hideModel(props)}
                        className="mr-4"
                    >
                        cancel
                    </Button> : ""}
                    {props.responseOnOk?<Button className="px-5"
                        variant="primary"
                        onClick={() => {props.responseOnOk ? responseClose(props): closeModel(props) }}
                    >
                        Close Preview
                    </Button>:""}
                    {props.closeBtn?<Button className="px-5"
                        variant="primary"
                        onClick={() => {closeModel(props) }}
                    >
                        close
                    </Button>:""}
                </Modal.Body>
            </Modal>
        </div>
    );
};
