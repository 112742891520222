/* eslint-disable jsx-a11y/anchor-is-valid */
import React ,{useEffect,useState} from "react";
import { Tab , Nav } from "react-bootstrap";
import axios from "axios";
import { NODE_API_URL,BASE_URL } from "../../../env_config";
import Cookies from "js-cookie"
// import { useLocation } from "react-router-dom";
import CcmodeThemeSetting from "./CcmodeThemeSetting";
import CcmodeTaxSetting from "./CcmodeTaxSetting"
import CcmodeLoginSetting from "./CcmodeLoginSetting"

export const CcmodeSettingNew = (props) => {
    
    // const [updatekey,setUpdateKey] = useState()
    const [key, setKey] = useState('ccmodetheme');
    // const location = useLocation()
    const [ccmodesetting,setCCModeSetting] = useState(null)
    useEffect(() => {
        if(props.userinfo.id){
            axios({
                method: "get",
                url: `${NODE_API_URL}/IVP/fetch-ccmode-setting-data`,
                headers: {
                    "Content-Type": "application/json",
                     "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
                }
            }).then(res => {
                if(res && res.data && res.data.data && res.data.data.length){
                    setCCModeSetting(res.data.data[0])
                }
            }).catch(error => {
                if(error?.response?.data?.message === "UnAuthorized!"){
                  var cookies = document.cookie.split(";");
                for (var i = 0; i < cookies.length; i++) {
                  if(cookies[i] && !cookies[i].includes("view")){
                    var equals = cookies[i].indexOf("=");
                    var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                  }
                }
                
                window.location.replace(`${BASE_URL}/logout`);
              }
              })
        }
    },[])
    // console.log(props.userinfo.id,"USERIDDDD")
    // console.log(ccmodesetting,"ccmodesetting")
    return (
        <div className="container-fluid feed-theme">
            <Tab.Container id="settingTab" activeKey={key} onSelect={(k) => setKey(k)}>
                <div className="row">
                    <div className="col-md-3 card custom-card border-0 rounded-0">
                        <div className="card-body p-3">
                            <Nav
                                variant="pills"
                                className="flex-column custom_nav"
                            >
                                <Nav.Item>
                                    <Nav.Link className="flex-wrap" eventKey="ccmodetheme">
                                        <span className="font-size-h5 px-0 col-12">CCMODE Theme</span >
                                        <span className="text-muted small px-0 col-12">Client Centric mode theme setting</span>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className="flex-wrap" eventKey="ccmodetax">
                                        <span className="font-size-h5 px-0 col-12">CCMODE Currency & Tax</span >
                                        <span className="text-muted small px-0 col-12">Client Centric mode Currency & Tax setting</span>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item >
                                    <Nav.Link className="flex-wrap" eventKey="ccmodelogin">
                                        <span className="font-size-h5 px-0 col-12">CCMODE Login</span >
                                        <span className="text-muted small px-0 col-12">Client Centric mode Login setting</span>
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                    </div>
                    <div className="col-md-9 card bg-white custom-card custom-scrollbar " style={{maxHeight:"85vh"}}>
                        <div className="card-body p-10" id="themesetting">
                            <Tab.Content>
                                <Tab.Pane eventKey="ccmodetheme">
                                    <CcmodeThemeSetting userinfo={props.userinfo} language={props.language} ccmodesetting={ccmodesetting}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="ccmodetax">
                                    <CcmodeTaxSetting userinfo={props.userinfo} language={props.language} ccmodesetting={ccmodesetting}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="ccmodelogin">
                                    <CcmodeLoginSetting userinfo={props.userinfo} language={props.language} ccmodesetting={ccmodesetting}/>
                                </Tab.Pane>
                            </Tab.Content>
                        </div>
                    </div>
                </div>
            </Tab.Container>
        </div>
    );
};
