import React from "react";
import GoogleMapReact from "google-map-react";
import MyMarker from "./MyMarker";
import mapLinkData from "./Maplink"
import styles from "../../../../app/modules/Auth/pages/css/style.module.css"

export class Map extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      defaultProps: {
        styles: this.props.styles || [],
        center: this.props.center || {
          lat: 22.298340 ,
          lng: 114.174360
        },
      }
    };
  }
  // lat={22.298340} lng={114.174360}
  render() {
    console.log(mapLinkData.id);
    return (
      <div className={styles.customMap}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyDOMP9G41YDFTPRj4y_K9KWbkZT29LvPL4",
          }}
          defaultCenter={this.state.defaultProps.center}
          defaultZoom={12}
          options={{ styles: this.state.defaultProps.styles }}
        > 
            <MyMarker
              lat={this.state.defaultProps.center.lat}
              lng={this.state.defaultProps.center.lng}
            />
        </GoogleMapReact>
      </div>
    );
  }
}

export default Map;